import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { NavmapEditorProps } from './types';
import { NavmapForm } from '../../../Editor/components/NavmapForm/NavmapForm';

export const RenderProposalMapEditor = ({
  proposals,
  handleContentUpdate,
  index,
  currentView,
}: NavmapEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();

  if (!proposalBlocks[index]) return null;
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    const { data } = proposalBlocks[index];
    return (
      <NavmapForm
        proposals={proposals}
        handleContentUpdate={handleContentUpdate}
        index={index}
        isProposal
        data={data.content}
      />
    );
  }
};
