import React from 'react';
import { useRouter } from 'next/router';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { ProposalEditorProps } from './types';
import { TitleEditor } from '../../../Editor/TitleEditor';

export const RenderTitleEditor = ({
  handleContentUpdate,
  index,
  currentView,
}: ProposalEditorProps) => {
  const router = useRouter();

  const [{ proposalsPageState }] = useEditModeContext();
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSALS) {
    if (!proposalsPageState?.[router.locale]?.blocks[index]) return null;
    const data = proposalsPageState?.[router.locale]?.blocks[index];
    return (
      <TitleEditor
        key={`${index}-title-key`}
        data={data}
        index={index}
        onContentUpdate={handleContentUpdate}
      />
    );
  }
  return null;
};
