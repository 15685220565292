import * as React from 'react';
import { useRouter } from 'next/router';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import { TilesSectionRender } from 'Client/pages/tiles/components/tilesSectionRender';
import { useEditModeContext } from 'Client/utils/hooks';
import { DraggableSection } from 'Organisms';
import { ProposalsViewWrapper } from './MobilePreview.styles';
interface ProposalsViewProps {
  onCurrentEditItem: (
    type: string,
    index: number,
    isBenchmark?: boolean,
    locked?: boolean
  ) => void;
}
export const ProposalsView: React.FC<ProposalsViewProps> = ({
  onCurrentEditItem,
}: ProposalsViewProps) => {
  const [{ proposalsPageState }] = useEditModeContext();
  const router = useRouter();

  if (
    !proposalsPageState[router.locale] ||
    !proposalsPageState[router.locale]?.blocks
  ) {
    return (
      <div dir="ltr">
        {"This page doesn't exist in this language, please add it."}
      </div>
    );
  }
  const proposalsPageBlocks = proposalsPageState[router.locale].blocks;

  const handleSectionClick = (
    type: string,
    index: number,
    locked?: boolean
  ) => {
    onCurrentEditItem(type, index, null, locked);
  };
  const nonDraggableBlocks = proposalsPageBlocks.slice(0, 2);
  const draggableBlocks = proposalsPageBlocks.slice(2);
  return (
    <ProposalsViewWrapper>
      {nonDraggableBlocks.map((section, index) => (
        <SectionBlock
          key={index}
          onClickCapture={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSectionClick(section.type, index, section.locked);
          }}
          data-testid="proposals-item-wrapper"
          data-scroll-id={section?._id}
        >
          <TilesSectionRender
            {...section}
            key={`${section?.type}-${index}`}
            handleSectionClick={handleSectionClick}
          />
        </SectionBlock>
      ))}
      <Droppable droppableId="existing-proposals-sections">
        {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
          <div ref={providedDrop.innerRef} data-testid="ProposalsView">
            {draggableBlocks.map((section, idx) => {
              const index = idx + 2; // 2 is the length of nonDraggableBlocks
              return (
                <DraggableSection
                  key={`${section.type}-${index}`}
                  id={`${section.type}-${index}`}
                  index={index}
                  isDraggingOver={snapshotDrop.isDraggingOver}
                >
                  <span
                    key={index}
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSectionClick(section.type, index, section.locked);
                    }}
                    data-testid="proposals-item-wrapper"
                    data-scroll-id={section?._id}
                  >
                    <TilesSectionRender
                      {...section}
                      key={`${section?.type}-${index}`}
                      handleSectionClick={handleSectionClick}
                    />
                  </span>
                </DraggableSection>
              );
            })}
          </div>
        )}
      </Droppable>
    </ProposalsViewWrapper>
  );
};

const SectionBlock = styled.span`
  ${({ theme }) => css`
    border: 0.0625rem solid transparent;
    display: block;
    &:hover {
      cursor: pointer;
      border-radius: 0.1875rem;
      border: 0.0625rem solid ${theme.colorMappings.editModePrimaryColor};
    }
  `}
`;
