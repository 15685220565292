import React from 'react';
import { NavigationalMapContent } from 'Client/pages/hub/types';
import { NavmapEditorProps } from './types';
import { NavmapForm } from '../../../Editor/components/NavmapForm/NavmapForm';

export const RenderNavmapEditor = ({
  proposals,
  handleContentUpdate,
  hubPageContent,
  index,
}: NavmapEditorProps) => {
  if (!hubPageContent) return null;
  const section = hubPageContent.content.sections[index];
  return (
    <NavmapForm
      proposals={proposals}
      handleContentUpdate={handleContentUpdate}
      index={index}
      data={{ ...section.content, _id: section._id } as NavigationalMapContent}
    />
  );
};
