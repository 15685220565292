import * as React from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import PenIconBase from '@material-ui/icons/CreateOutlined';
import MuiButton from '@material-ui/core/Button';
import { usePermissions } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import { getEditModePath } from 'Client/pages/edit/components/EditModeButton/getEditModePath';

export const EditMapButton = (): JSX.Element => {
  const { can } = usePermissions();
  const { asPath, query, locale, push } = useRouter();

  const handleClick = () => {
    push(getEditModePath(asPath, query, locale));
  };

  return (
    <>
      {can(Permissions.MAP_EDIT_MODE) && (
        <Button data-testid="editMapButton" onClick={handleClick}>
          <PenIcon data-testid="icon" color="inherit" fontSize="inherit" />
        </Button>
      )}
    </>
  );
};

const Button = styled(MuiButton)`
  background-color: ${({ theme }) => theme.colorMappings.editModePrimaryColor};
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.white[500]};
  font-weight: bold;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 1rem;
  width: auto;
  min-width: 3.75rem;
  height: 3.75rem;
  position: absolute;
  bottom: 10rem;
  z-index: 1;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colorMappings.editModePrimaryColor};
  }

  ${({ theme }) => css`
    ${theme.direction === 'rtl' ? 'left' : 'right'}: 1.5rem;

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `};
`;

const PenIcon = styled(PenIconBase)`
  color: ${({ theme }) => theme.colors.white[500]};
`;
