import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useEditModeContext } from 'Client/utils/hooks';
import { Input, Label } from '../Form';
import { PROPOSALS_PAGE_ACTIONS } from '../../constants';
import { TitleEditorProps } from './types';

export const TitleEditor: React.FC<TitleEditorProps> = ({
  data,
  index,
  onContentUpdate,
}: TitleEditorProps) => {
  const { t } = useTranslation('customer');
  const router = useRouter();
  const [, { dispatchProposalsPage }] = useEditModeContext();

  const [value, setValue] = React.useState<string>(data.content.title);

  const handleChange = (value: string) => {
    const newContent = { ...data.content, title: value };
    setValue(value);
    dispatchProposalsPage({
      type: PROPOSALS_PAGE_ACTIONS.UPDATE_BLOCK_CONTENT,
      payload: { blockContent: newContent, index, language: router.locale },
    });
    onContentUpdate({ ...data, content: newContent });
  };

  return (
    <>
      <Label htmlFor="input-editor" label={t('Title')} />
      <Input
        id="input-editor"
        name={data.content.title}
        type="text"
        onChange={(e) => handleChange(e.target.value)}
        value={value}
      />
    </>
  );
};
