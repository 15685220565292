import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { TwoColumnsEditor } from '../../../Editor';
import { CommonEditorProps, TwoColumnsEditorProps } from './types';

export const RenderTwoColumnsEditor = ({
  currentView,
  handleContentUpdate,
  index,
  hubPageContent,
}: CommonEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    if (!proposalBlocks[index]) return null;
    const { data } = proposalBlocks[index];

    return (
      <TwoColumnsEditor
        data={data}
        index={index}
        onContentUpdate={handleContentUpdate}
        isHub={false}
      />
    );
  }
  if (currentView.type === EDITABLE_PAGE_TYPES.HUB) {
    if (!hubPageContent) return null;
    const {
      content: { sections },
    } = hubPageContent;
    const data = sections[index] as TwoColumnsEditorProps['data'];
    return (
      <TwoColumnsEditor
        data={data}
        index={index}
        onContentUpdate={handleContentUpdate}
        isHub={true}
      />
    );
  }
};
