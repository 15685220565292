import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { ImageContentEditor } from '../../../Editor';
import { CommonEditorProps } from './types';

export const RenderImageAndGridEditor = ({
  handleContentUpdate,
  index,
}: CommonEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();

  if (!proposalBlocks[index]) return null;

  const { data } = proposalBlocks[index];
  return (
    <ImageContentEditor
      key={`${index}-image-content-key`}
      data={data}
      index={index}
      onContentUpdate={handleContentUpdate}
    />
  );
};
