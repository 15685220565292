import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { PinIcon } from 'Icons';
import { Permissions } from 'Client/constants/permissions';
import {
  usePermissions,
  useProject,
  useUser,
  useMap,
} from 'Client/utils/hooks';
import { userHasPermission } from 'Client/utils/user';
import { ProposalStage } from 'Pages/proposals/types';
import { ProjectStage } from 'Shared/types';
import { useBanner } from 'Client/utils/hooks/useBanner';
import {
  Button,
  CancelButton,
  Description,
  Header,
  Label,
} from './HaveYourSayButton.styles';
import { HaveYourSayProps } from './types';

const HaveYourSayButton = ({
  proposal,
  onClick,
  onCancelDrawing,
}: HaveYourSayProps): JSX.Element => {
  const { t } = useTranslation();
  const { can } = usePermissions();
  const { user } = useUser();
  const project = useProject();
  const router = useRouter();
  const isEmbed = router?.query?.embed === 'true';
  const seeQuestionsAfterClosure = can(Permissions.SEE_QUESTIONS_AFTER_CLOSURE);

  let display = false;

  if (
    project.stage === ProjectStage.TESTING ||
    proposal.stage === ProposalStage.DRAFT
  ) {
    display = userHasPermission(user, Permissions.SEE_PROJECT_BEFORE_LAUNCH);
  }

  if (
    project.stage === ProjectStage.ACTIVE &&
    (proposal.stage === ProposalStage.ACTIVE ||
      (proposal.stage === ProposalStage.COMPLETED && seeQuestionsAfterClosure))
  ) {
    display = true;
  }
  if (proposal?.featureFlags?.notCommentable && !seeQuestionsAfterClosure) {
    display = false;
  }
  const {
    dispatch,
    state: { isContributionFlowStarted },
  } = useMap();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    const handleKeyUp = ({ key }) => {
      if (key === 'Escape') {
        onCancelDrawing();
      }
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [onCancelDrawing]);

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return display ? (
    <>
      {isContributionFlowStarted ? (
        <Button
          data-testid="placeThePinButton"
          isFlowStarted={true}
          isEmbed={isEmbed}
          bannerHeight={errorBannerHeight + stageBannerHeight}
        >
          <Label isFlowStarted={true}>
            <Trans>
              <Header>
                <PinIcon viewBox="0 0 580 500" />
                {'Place the pin'}
              </Header>
              <Description>{"where you'd like to comment"}</Description>
            </Trans>
            <CancelButton
              onClick={onCancelDrawing}
              label={!isMobile ? t('Cancel') : null}
            />
          </Label>
        </Button>
      ) : (
        <Button
          data-testid="haveYourSayButton"
          isFlowStarted={false}
          isEmbed={isEmbed}
          bannerHeight={errorBannerHeight + stageBannerHeight}
          onClick={() => {
            dispatch({
              type: 'SET_CONTRIBUTION_FLOW_STARTED',
              payload: true,
            });
            onClick();
          }}
        >
          <Label>{t('Have your say')}</Label>
        </Button>
      )}
    </>
  ) : null;
};

export { HaveYourSayButton };
