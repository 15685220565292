import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiTypography from '@material-ui/core/Typography';
import { lighten } from 'polished/lib';
import { Button as ButtonBase } from 'Molecules';
import {
  PlusIcon as PlusIconBase,
  CircleIcon as CircleIconBase,
  PolygonIcon as PolygonIconBase,
  MarkerIcon as MarkerIconBase,
} from 'Atoms/Icons';
import { Modal as ModalBase } from 'Molecules/Modal';

export const PlusIcon = styled((props) => <PlusIconBase {...props} />)`
  margin-right: 0.63rem;
`;

export const OpenButton = styled((props) => <ButtonBase {...props} />)`
  background-color: ${({ theme }) => theme.colorMappings.editButtonBackground};
  border-radius: 2rem;
  border: 0;
  padding: 0.9375rem;
  width: 9.375rem;
  height: 2.8125rem;
  color: ${({ theme }) => theme.colors.white[500]};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  left: 25.9375rem;
  top: 9.125rem;
  z-index: 1;
  display: flex;
  justify-content: space-around;

  &:hover {
    color: ${({ theme }) => theme.colors.white[500]};
    background-color: ${({ theme }) =>
      theme.colorMappings.editButtonBackground};
    border: 0;
  }
`;

export const Menu = styled((props) => <MuiMenu {...props} />)`
  margin-top: 4rem;
`;

export const MenuItem = styled((props) => <MuiMenuItem {...props} />)`
  width: 100%;
  justify-content: flex-start;
  padding-left: 0.94rem;
  padding-top: 0.63rem;
  padding-bottom: 0.63rem;

  ${({ theme }) => css`
    &:hover {
      background-color: ${lighten(
        0.3,
        theme.colorMappings.editModePrimaryColor
      )};
    }
  `}
`;

export const CircleIcon = styled((props) => <CircleIconBase {...props} />)`
  margin-right: 0.94rem;
`;

export const PolygonIcon = styled((props) => <PolygonIconBase {...props} />)`
  margin-right: 0.94rem;
`;

export const MarkerIcon = styled((props) => <MarkerIconBase {...props} />)`
  margin-right: 0.94rem;
`;

export const Modal = styled((props) => <ModalBase {...props} />)`
  .MuiPaper-root {
    padding: 0.94rem 0;
    width: 18.13rem;
    height: unset;
    min-height: 9.4rem;

    > .MuiButtonBase-root:first-child {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
    }

    .MuiSvgIcon-root {
      width: 0.8125rem;
      height: 0.8125rem;
    }
  }
`;

export const ModalTitle = styled((props) => <MuiTypography {...props} />)`
  align-items: center;
  display: flex;
  padding: 0rem 0.94rem;
  font-weight: 700;
`;

export const ModalDecription = styled((props) => <MuiTypography {...props} />)`
  align-items: center;
  display: flex;
  padding: 0rem 0.94rem;
`;

export const ModalFooter = styled.div`
  width: 100%;
  height: 3.44rem;
  border-top: 1px solid ${({ theme }) => theme.colorMappings.separators};
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.94rem;
`;

export const ModalFooterButton = styled((props) => <MuiButton {...props} />)<{
  inverse?: boolean;
}>`
  font-size: 1rem;
  border-radius: 3.13rem;
  background-color: ${({ theme }) => theme.colorMappings.editButtonBackground};
  color: ${({ theme }) => theme.colors.white[500]};
  min-width: 4.69rem;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colorMappings.editButtonBackground};
    color: ${({ theme }) => theme.colors.white[500]};
  }

  ${({ inverse }) =>
    inverse &&
    css`
      color: ${({ theme }) => theme.colorMappings.editButtonBackground};
      background-color: transparent;

      &:hover {
        color: ${({ theme }) => theme.colorMappings.editButtonBackground};
        background-color: transparent;
      }
    `}
`;

export const InvisbleInput = styled.input`
  display: none;
`;
