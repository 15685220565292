import React from 'react';
import { useRouter } from 'next/router';
import { useEditModeContext } from 'Client/utils/hooks';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { NoSSRFroala } from '../../../Editor';
import { DescriptionEditorProps } from './types';

export const RenderDescriptionEditor = ({
  handleContentUpdate,
  index,
  currentView,
  displayTrackChanges,
  setIsCodeViewActive,
}: DescriptionEditorProps) => {
  const router = useRouter();

  const [{ proposalBlocks, proposalsPageState }] = useEditModeContext();
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    if (!proposalBlocks[index]) return null;
    const { data } = proposalBlocks[index];
    return (
      <NoSSRFroala
        key={`${index}-description-key`}
        onContentUpdate={handleContentUpdate}
        content={data.content}
        currentView={currentView}
        index={index}
        hasTrackChangesButton={displayTrackChanges}
        setFirstColumnActive={setIsCodeViewActive}
      />
    );
  }
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSALS) {
    if (!proposalsPageState[router.locale]?.blocks[index]) return null;
    const data = proposalsPageState[router.locale]?.blocks[index];
    return (
      <NoSSRFroala
        key={`${index}-description-key`}
        onContentUpdate={handleContentUpdate}
        content={data.content.html}
        currentView={currentView}
        index={index}
        hasTrackChangesButton={displayTrackChanges}
        setFirstColumnActive={setIsCodeViewActive}
        blockId={data._id}
      />
    );
  }
};
