import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { RevisionItemProps } from './types';
import {
  Text,
  CurrentVersion,
  DateText,
  VersionLinkContainer,
  VersionLink,
  Content,
  RevisionItemContainer,
  Link,
} from './Revision.styles';

export const RevisionItem: React.FC<RevisionItemProps> = (
  props: RevisionItemProps
) => {
  const { t } = useTranslation('customer');
  const {
    _id,
    timeStamp,
    isCurrentVersion,
    isItemHovered,
    onSwitchVersion,
    type,
  } = props;

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [editor, setEditor] = React.useState('unknown');

  const handleData = async () => {
    try {
      const res = await fetch(`/api/versions/${_id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const json = await res.json();
      setFirstName(json.data?.firstName || '');
      setLastName(json.data?.lastName || '');
      setEmail(json.data?.email);
    } catch (error) {
      captureException(
        `Error in handleData() at RevisionItem.tsx: ${error.toString()}`
      );
    }
  };

  React.useEffect(() => {
    handleData();
  }, [_id]);

  React.useEffect(() => {
    if (firstName && lastName) {
      setEditor(`${firstName} ${lastName}`);
    } else {
      setEditor(email);
    }
  }, [firstName, lastName, email]);

  return (
    <RevisionItemContainer data-versionid={_id}>
      <Content>
        <DateText>
          {format(new Date(timeStamp.toString()), 'do MMMM y HH:mm')}
        </DateText>
        <br />
        <Text>{t('by {{editor}}', { editor })}</Text>
      </Content>
      {isCurrentVersion && (
        <CurrentVersion>{t('Current version')}</CurrentVersion>
      )}
      {!isCurrentVersion && isItemHovered && (
        <VersionLinkContainer>
          <Link
            href={`/preview/${type}/${_id}`}
            target="_blank"
            rel="noreferrer"
          >
            <VersionLink>{t('Preview this version')}</VersionLink>
          </Link>

          <span>{' | '}</span>

          <Link onClick={() => onSwitchVersion(_id)}>
            <VersionLink>{t('Switch to version')}</VersionLink>
          </Link>
        </VersionLinkContainer>
      )}
    </RevisionItemContainer>
  );
};
