import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useEditModeContext } from 'Client/utils/hooks';
import { TextInput } from 'Client/components/molecules/TextInput/TextInput';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import {
  AvailableColoursPopup,
  ColorItem,
  ColourSelector,
  CustomizeTabWrapper,
  ItemSection,
  ItemsWrapper,
} from './CustomizeTab.styles';
import { Label } from '../../Form';

interface CustomizeTabProps {
  activeTab: boolean;
  index: number;
  type: EDITABLE_CONTENT_BLOCK_TYPES;
  onContentUpdate: (content: unknown) => void;
}
const CustomizeTab: React.FC<CustomizeTabProps> = ({
  activeTab,
  index,
  type,
  onContentUpdate,
}) => {
  const [{ proposalBlocks }, { dispatchProposalBlocks }] = useEditModeContext();
  const dispatchRdx = useDispatch();
  if (!proposalBlocks[index]) return null;
  const { data } = proposalBlocks[index];
  const router = useRouter();

  const [colour, setColour] = React.useState(
    data?.backgroundColour || '#F9F9F9'
  );
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [item, setItem] = React.useState(proposalBlocks[index].data);
  const [questionContent, setQuestionContent] = React.useState(
    data.content || proposalBlocks[index].data.content
  );
  React.useEffect(() => {
    setColour(data.backgroundColour || '#F9F9F9');
    setItem(proposalBlocks[index].data);
    setQuestionContent(data.content || proposalBlocks[index].data.content);
  }, [index, data?.content?.type]);

  const { t } = useTranslation('customer');

  const handleColourClick = () => {
    setPopupOpen(!popupOpen);
  };
  const handleChange = () => {
    setPopupOpen(false);
    const _content = {
      type,
      index,
      content: questionContent,
      backgroundColour: colour,
    };
    dispatchProposalBlocks(_content);
    dispatchRdx(update({ ..._content, lang: router.locale }));
    setItem(_content);
  };

  React.useEffect(() => {
    handleChange();
  }, [colour]);
  React.useEffect(() => {
    onContentUpdate({
      ...data.content,
      questionContent,
    });
  }, [item]);

  const availableColours = [
    '#F8FCFF',
    '#F6F9F6',
    '#FFFDE7',
    '#F9F8FC',
    '#FFFEF0',
    '#F8FFFD',
    '#E9F2FF',
    '#FFFEF7',
    '#FFF8F7',
    '#FFFFFF',
    '#F2F2F2',
    '#F9F9F9',
  ];
  return (
    <CustomizeTabWrapper
      data-testId="edit-mode-customize-tab"
      activeTab={activeTab}
    >
      <div>
        <Label htmlFor="heading" label={t('Background colour')} bold />
        <ItemsWrapper>
          <ColourSelector>
            <p>{t('Colour')}</p>
            <ColorItem
              color={colour}
              onClick={handleColourClick}
              data-testid="edit-mode-customize-color-button"
            />
            {popupOpen && (
              <AvailableColoursPopup data-testid="edit-mode-customize-available-colors-popup">
                {availableColours.map((availableColour, index) => (
                  <ColorItem
                    selected={colour === availableColour}
                    customSize={{ width: '1.625rem', height: '1.625rem' }}
                    data-testid={`edit-mode-customize-available-color-${index}`}
                    key={availableColour}
                    color={availableColour}
                    onClick={() => setColour(availableColour)}
                  />
                ))}
              </AvailableColoursPopup>
            )}
          </ColourSelector>
          <ItemSection>
            <p>{t('Hex')}</p>
            <TextInput
              placeholder={t('Colour hex')}
              type="text"
              value={colour}
              onChange={(e) => setColour(e.target.value)}
              disabled={true}
            />
          </ItemSection>
        </ItemsWrapper>
      </div>
    </CustomizeTabWrapper>
  );
};

export default CustomizeTab;
