import { isErrorObject } from 'Client/utils/guards';
import { getUploadSignature } from './getUploadSignature';
import { uploadFormData } from './uploadFormData';

const uploadImageToCloudinary = async (
  folder: string,
  filename: string,
  imageSrc: string
) => {
  const uploadSignature = await getUploadSignature({
    folder,
    public_id: filename,
    invalidate: true,
  });
  if (isErrorObject(uploadSignature)) {
    throw new Error(
      `Failed to get Cloudinary upload signature: ${uploadSignature.error}`
    );
  }

  const { key, signature, timestamp } = uploadSignature;
  const formData = new FormData();
  formData.append('file', imageSrc);
  formData.append('api_key', key);
  formData.append('signature', signature);
  formData.append('invalidate', 'true');
  formData.append('timestamp', timestamp);
  formData.append('folder', folder);
  formData.append('public_id', filename);

  const uploadRes = await uploadFormData(formData);
  if (isErrorObject(uploadRes)) {
    throw new Error('Failed to upload file to Cloudinary: ' + uploadRes.error);
  }

  return uploadRes.secure_url;
};

export default uploadImageToCloudinary;
