import React from 'react';
import { HtmlDescription } from 'Client/components/molecules';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { DynamicProposals } from 'Client/pages/hub/components';
import { PageHeader } from './tilesSectionRender.styles';

export const TilesSectionRender = ({
  type,
  content,
  _id,
}: {
  type: EDITABLE_CONTENT_BLOCK_TYPES;
  content: any;
  _id?: string;
}) => {
  switch (type) {
    case EDITABLE_CONTENT_BLOCK_TYPES.TITLE:
      return (
        <PageHeader variant="h1" data-testid="tiles-page-title">
          {content?.title || ''}
        </PageHeader>
      );
    case EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION:
      return <HtmlDescription>{content?.html || ''}</HtmlDescription>;
    case EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS:
      return (
        <DynamicProposals
          content={content}
          ignoreHidden={_id === 'all-other-tiles'}
        />
      );
    default:
      return <div>{`Unknown block type: ${type}`}</div>;
  }
};
