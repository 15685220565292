import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { ProjectTeamHeaderEditor } from '../../../Editor';
import { CommonEditorProps } from './types';

export const RenderProjectTeamEditor = ({
  handleContentUpdate,
  index,
}: CommonEditorProps) => {
  const [{ projectTeamPage }] = useEditModeContext();
  if (!projectTeamPage) return null;
  const {
    content: { title, description },
  } = projectTeamPage;
  return (
    <ProjectTeamHeaderEditor
      content={{ title, description }}
      onContentUpdate={handleContentUpdate}
      key={`${index}-project-team-header-key`}
    />
  );
};
