import React from 'react';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { InputEditor } from '../../../Editor';
import { ProposalEditorProps } from './types';
export const RenderInputEditor = ({
  handleContentUpdate,
  index,
  currentView,
}: ProposalEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();
  if (!proposalBlocks[index]) return null;
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    const { data } = proposalBlocks[index];
    return (
      <InputEditor
        key={`${index}-input-key`}
        data={data}
        index={index}
        onContentUpdate={handleContentUpdate}
      />
    );
  }
};
