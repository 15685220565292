import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { AllProposalStepsComponent } from 'Client/pages/proposals';
import { RootState } from 'Client/redux-store';
import { PageViewProps } from './types';

export const ProposalView: React.FC<PageViewProps> = ({
  onCurrentEditItem,
}: PageViewProps) => {
  const router = useRouter();
  const proposalViewRdx = useSelector(
    (state: RootState) => state.editModeProposalView
  );

  if (
    !proposalViewRdx[router.locale] ||
    !proposalViewRdx[router.locale]?.content
  ) {
    return (
      <div dir="ltr">
        {"This proposal doesn't exist in this language, please add it."}
      </div>
    );
  }
  const {
    content: { steps },
  } = proposalViewRdx[router.locale];

  const handleSectionClick = (type: string, index: number) => {
    onCurrentEditItem(type, index);
  };

  return (
    <AllProposalStepsComponent
      steps={steps}
      onSectionClick={handleSectionClick}
    />
  );
};
