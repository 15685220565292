import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { HtmlDescription } from 'Molecules';

export const FullWidthContainer = styled.div`
  width: 100%;
  padding-bottom: 4.7rem;
`;

export const StakeholdersWrapper = styled.div`
  padding: 0 2rem;
  max-width: 75rem;
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0;
      width: 100%;
    }
  `}
`;

export const Heading = styled(Typography)`
  margin-top: 5rem;
  font-size: 1.6rem;
`;

export const Intro = styled(HtmlDescription)`
  margin-top: 1.6rem;
`;
