import React from 'react';
import { useSelector } from 'react-redux';
import { ConsentsOption } from 'Client/pages/preferences/types';
import { RootState } from 'Client/redux-store';
import { ConsentEditor } from '../../../Editor';
import { CustomConsentEditor } from './types';

export const RenderCustomConsentEditor = ({
  handleContentUpdate,
  index,
  filterCustom,
  locale,
}: CustomConsentEditor) => {
  const preferencesBlocksRdx = useSelector(
    (s: RootState) => s.editModePreferencesBlocks
  );
  const customConsents = preferencesBlocksRdx[locale].filter(filterCustom);
  if (!customConsents[index]) null;
  return (
    <ConsentEditor
      key={`${index}-preferences-consent-key`}
      onContentUpdate={handleContentUpdate}
      index={index}
      content={customConsents[index].data.content as ConsentsOption}
    />
  );
};
