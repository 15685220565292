import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { DynamicProposalsContent } from 'Shared/types/proposalPage';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { DynamicProposalsEditor } from '../../../Editor';
import { CommonEditorProps } from './types';

export const RenderProposalDynamicProposalsEditor = ({
  handleContentUpdate,
  index,
}: CommonEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();

  const dynamicProposals = proposalBlocks[index];
  if (!dynamicProposals || !dynamicProposals.data) return null;
  const content: DynamicProposalsContent = dynamicProposals.data
    .content as DynamicProposalsContent;

  return (
    <DynamicProposalsEditor
      pageType={EDITABLE_PAGE_TYPES.PROPOSAL}
      content={content}
      index={index}
      contextDispatcher={handleContentUpdate}
      id={dynamicProposals?.data?._id}
    />
  );
};
