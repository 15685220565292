import * as React from 'react';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { RootState } from 'Client/redux-store';
import { DraggableSection } from 'Organisms';
import {
  ConsentsContainer,
  Heading,
} from 'Client/pages/preferences/components';
import { IntroDescription } from 'Client/pages/preferences/PreferencesPage.styles';
import { ConsentsDescription } from 'Client/pages/preferences/components/ConsentsContainer.styles';
import { ConsentsOption as ConsentsType } from 'Client/pages/preferences/types';
import { ConsentsOption } from 'Client/pages/preferences/components/ConsentsOption';
import { Typography } from 'Atoms';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../../constants';
import { PageViewProps } from './types';

export const PreferencesView: React.FC<PageViewProps> = ({
  onCurrentEditItem,
}: PageViewProps) => {
  const router = useRouter();
  const preferencesViewRdx = useSelector(
    (state: RootState) => state.editModePreferencesView
  );

  if (
    !preferencesViewRdx[router.locale] ||
    !preferencesViewRdx[router.locale]?.content
  )
    return <></>;

  const {
    content: { heading, description, defaultConsents, customConsents },
  } = preferencesViewRdx[router.locale];
  return (
    <Droppable droppableId="existing-hub-sections">
      {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
        <div
          ref={providedDrop.innerRef}
          style={{ position: 'relative', padding: '2rem' }}
        >
          <Heading heading={heading} />
          <IntroDescription>{description}</IntroDescription>
          <ConsentsContainer
            consentsGroup={defaultConsents}
            onConsentsToggle={() => null}
          />
          {customConsents?.consents && (
            <div style={{ marginTop: 3 }}>
              <Typography variant="h3" gutterBottom>
                {customConsents?.title}
              </Typography>
              {[customConsents?.description, customConsents?.helperText].map(
                (text) =>
                  text && (
                    <ConsentsDescription key={text}>{text}</ConsentsDescription>
                  )
              )}

              {customConsents.consents.map(
                (consent: ConsentsType, index: number) => (
                  <DraggableSection
                    key={`${consent.heading}-${index}`}
                    id={`${consent.heading}-${index}`}
                    index={index}
                    isDraggingOver={snapshotDrop.isDraggingOver}
                  >
                    <span
                      key={index}
                      onClickCapture={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onCurrentEditItem(
                          EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT,
                          index
                        );
                      }}
                      data-testid="consent-item-wrapper"
                    >
                      <ConsentsOption
                        key={consent.order}
                        {...consent}
                        onConsentsToggle={() => null}
                      />
                    </span>
                  </DraggableSection>
                )
              )}
            </div>
          )}
        </div>
      )}
    </Droppable>
  );
};
