import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { MediaUpload } from '../../../Editor';
import { CommonEditorProps } from './types';

export const RenderMediaUploadEditor = ({
  handleContentUpdate,
  index,
}: CommonEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();

  if (proposalBlocks[index]) {
    const { data } = proposalBlocks[index];
    return (
      <MediaUpload
        onContentUpdate={handleContentUpdate}
        data={data}
        key={`${index}-media-upload-key`}
        index={index}
      />
    );
  }
};
