import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Typography } from 'Atoms';
import { TextArea, TextField } from 'Molecules';
import { ConfirmationModal } from 'Organisms';
import { CardContent } from 'Client/pages/proposals';
import { ProposalStage } from 'Client/pages/proposals/types';
import { ProjectStage } from 'Shared/types';
import { usePermissions, useProject } from 'Client/utils/hooks';
import { convertToSlug } from 'Client/utils/stringManipulations';
import { deleteProposalPage } from 'Client/services/page';
import {
  updateCard,
  updateSlug,
} from 'Client/utils/reduxReducers/editMode/proposalViewReducer';
import { RootState } from 'Client/redux-store';
import { theme } from 'Client/components/theme';
import { Permissions } from 'Client/constants/permissions';
import { UPDATE_CUSTOM_LAYERS } from 'Client/services/map/updateCustomLayers.gql';
import { SelectedViewOption } from '../../types';
import { Title } from './Revision.styles';
import { EditImageSection } from '.';
import {
  PanelContent,
  SmallProposalCard,
  DeleteButton,
  DuplicateTileButton,
  DuplicateTileSection,
} from './ProposalCardEditPanel.styles';
import { getSlugReference } from './utils/getSlugReference';
import { CTADropdown } from './components/CTADropdown.tsx';
import { DuplicateTileModal } from '../DuplicateTileModal';

type Props = {
  editablePages: Array<SelectedViewOption>;
};

const initCardValue = {
  title: '',
  image: { src: '', alt: '' },
};

export const ProposalCardEditPanel: React.FC<Props> = ({
  editablePages,
}: Props) => {
  const project = useProject();
  const router = useRouter();
  const dispatchRdx = useDispatch();
  const proposalViewRdx = useSelector((s: RootState) => s.editModeProposalView);
  const { t } = useTranslation('customer');
  const { can } = usePermissions();
  const client = useApolloClient();

  const [cardValue, setCardValue] = React.useState<CardContent>(initCardValue);
  const [froalaImage, setFroalaImage] = React.useState({ src: '' });
  const [slugField, setSlugField] = React.useState('');
  const [initialSlug, setInitialSlug] = React.useState('');
  const [slugError, setSlugError] = React.useState('');
  const [validatedSlug, setValidatedSlug] = React.useState('');
  const [slugReference, setSlugReference] = React.useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = React.useState(false);

  const canDuplicateTiles = can(Permissions.IMPORT_TILES);
  const isProposalCardV2Enabled = project?.features?.proposalCardV2 ?? false;
  theme;
  React.useEffect(() => {
    if (
      proposalViewRdx[router.locale] &&
      proposalViewRdx[router.locale]?.content?.card
    ) {
      const { card } = proposalViewRdx[router.locale].content;
      setCardValue(card);
      setFroalaImage({
        src: card.image?.src,
      });
    }
  }, [proposalViewRdx[router.locale]]);
  React.useEffect(() => {
    if (
      proposalViewRdx[router.locale] &&
      proposalViewRdx[router.locale]?.stage === ProposalStage.DRAFT &&
      proposalViewRdx[router.locale]?.slug
    ) {
      const { slug } = proposalViewRdx[router.locale];
      setSlugReference(getSlugReference(slug, editablePages));
      setSlugField(slug);
      setValidatedSlug(convertToSlug(slug));
      setInitialSlug(slug);
      setSlugError('');
    }
  }, [proposalViewRdx[router.locale]?._id]); // get in every time the id changes

  const handleTextChange = (propName: string, value: string | number) => {
    setCardValue({ ...cardValue, [propName]: value });
    updateProposalPage({ ...cardValue, [propName]: value });
  };
  const handleImageTextChange = (propName: string, value: string | number) => {
    const newContent = {
      ...cardValue,
      image: { ...cardValue.image, [propName]: value },
    };
    setCardValue(newContent);
    updateProposalPage(newContent);
  };
  const updateProposalPage = (cardContent: CardContent) => {
    dispatchRdx(updateCard({ card: cardContent, lang: router.locale }));
  };

  const handleImageContentUpdate = async (action: {
    type: string;
    content: { src: string };
  }) => {
    if (action.content.src !== cardValue.image.src) {
      const newContent = {
        ...cardValue,
        image: { ...cardValue.image, src: action.content.src },
      };
      updateProposalPage(newContent);

      const filters = [
        {
          "metadata->>'proposalId'": {
            jsonEq: proposalViewRdx[router.locale]?._id,
          },
        },
      ];

      const updates = {
        metadata: `jsonb_set(metadata, '{imageUrl}', '"${action.content.src}"')`,
      };
      await client.mutate({
        mutation: UPDATE_CUSTOM_LAYERS,
        variables: {
          filters: filters,
          updates: updates,
        },
      });
    }
  };
  const handleSlugChange = (
    e: React.ChangeEvent<{
      value: string;
    }>
  ) => {
    const { value } = e.target;
    const newSlug = convertToSlug(value);
    setSlugField(value);
    setValidatedSlug(newSlug);
    if (!newSlug) {
      // If user has set empty value, set error and restore old slug to context
      setSlugError('Slug must have a value');
      dispatchRdx(updateSlug({ slug: initialSlug }));
      return;
    }
    if (slugReference.includes(newSlug)) {
      // If new slug already in use, set error and restore old slug to context
      setSlugError('Slug already in use (' + newSlug + ')');
      dispatchRdx(updateSlug({ slug: initialSlug }));
      return;
    }
    // Clear any error and set new slug to context
    setSlugError('');
    dispatchRdx(updateSlug({ slug: newSlug }));
  };
  const handleToggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };
  const handleDeletePage = async () => {
    const res = await deleteProposalPage(
      proposalViewRdx[router.locale]._id,
      proposalViewRdx[router.locale].slug,
      project.id
    );
    if (res.success) {
      setIsConfirmModalOpen(false);
      router.push('/edit');
    }
  };

  const noContentForLang = !proposalViewRdx[router.locale]?._id;
  if (noContentForLang) {
    return (
      <PanelContent data-testid="ProposalCardEditPanel-no-content">
        <div>
          {t(
            'No version found for this proposal page for this language. Please add one in the database.'
          )}
        </div>
      </PanelContent>
    );
  }
  return (
    <PanelContent data-testid="ProposalCardEditPanel">
      <Title>{t('Summary')}</Title>
      <Typography>
        {t(
          `Information shown on page preview cards when signposting visitors and respondents to other pages they can explore.`
        )}
      </Typography>
      {Object.keys(cardValue).length > 0 && (
        <SmallProposalCard {...cardValue} />
      )}
      <EditImageSection
        cardValue={cardValue}
        froalaImage={froalaImage}
        onImageContentUpdate={handleImageContentUpdate}
        onImageTextChange={handleImageTextChange}
      />
      <Box mb={1}>
        <TextField
          label={t('Page title')}
          showLabel
          value={cardValue.title}
          handleChange={(e) => handleTextChange('title', e.target.value)}
        />
      </Box>
      <Box mb={1}>
        <TextArea
          label={t('Page description')}
          value={cardValue.description}
          handleChange={(e) => handleTextChange('description', e.target.value)}
        />
      </Box>
      {isProposalCardV2Enabled && (
        <CTADropdown
          onChange={(value) => handleTextChange('ctaText', value)}
          defaultValue={cardValue.ctaText}
        />
      )}
      {proposalViewRdx[router.locale]?.stage === ProposalStage.DRAFT && (
        <Box mb={1}>
          <TextField
            label={t('Proposal URL')}
            showLabel
            value={slugField}
            handleChange={handleSlugChange}
            status={{
              type: slugError ? 'error' : 'info',
              message: slugError || `Result: \n/${validatedSlug}`,
            }}
          />
        </Box>
      )}
      {canDuplicateTiles && (
        <>
          <DuplicateTileSection>
            <Title>{t('Import content from another tile')}</Title>
            <Trans>
              <p>
                You can import all the content and questions from another tile,
                from this project and all other projects where you have the
                necessary permissions (lead admin or admin).
              </p>
              <strong>
                All the content imported will be added at the end of this tile,
                after a page break.
              </strong>
            </Trans>
            <DuplicateTileButton onClick={() => setDuplicateModalOpen(true)}>
              {t('Import content')}
            </DuplicateTileButton>
          </DuplicateTileSection>
          <DuplicateTileModal
            open={duplicateModalOpen}
            onClose={() => setDuplicateModalOpen(false)}
            pageTypes={['proposal']}
          />
          <ConfirmationModal
            open={isConfirmModalOpen}
            title={t('Delete page')}
            description={t(
              `Are you sure you want to delete this page and all it's content? This means any links for this page that have already been shared will no longer work.`
            )}
            confirmLabel={t('Yes, delete page')}
            discardLabel={t('No, keep page')}
            onClose={handleToggleConfirmModal}
            onConfirm={handleDeletePage}
            onDiscard={handleToggleConfirmModal}
          />
        </>
      )}
      {(proposalViewRdx[router.locale]?.stage === ProposalStage.DRAFT ||
        project.stage === ProjectStage.TESTING) && (
        <Box mb={1}>
          <DeleteButton
            data-testid="ProposalCardEditPanel-delete-page-button"
            borderRadius="3.125rem"
            onClick={handleToggleConfirmModal}
          >
            {t('Delete this page')}
          </DeleteButton>
        </Box>
      )}
    </PanelContent>
  );
};
