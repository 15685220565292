import React from 'react';
import { TextContent } from 'Client/pages/hub/types';
import { NoSSRFroala } from '../../../Editor';
import { TextEditorProps } from './types';

export const RenderTextEditor = ({
  hubPageContent,
  handleContentUpdate,
  index,
  currentView,
  displayTrackChanges,
}: TextEditorProps) => {
  if (!hubPageContent) return null;
  const { sections } = hubPageContent.content;
  const data = sections[index].content as TextContent;
  return (
    <NoSSRFroala
      key={`${index}-text-key`}
      onContentUpdate={handleContentUpdate}
      content={data ? data?.html : ''}
      blockId={sections[index]._id}
      currentView={currentView}
      index={index}
      hasTrackChangesButton={displayTrackChanges}
    />
  );
};
