import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import {
  Wrapper,
  WithPadding,
  QuestionsWrapper,
} from 'Client/pages/demographics/components/SpecialCategoryQuestionsContainer.styles';
import { DraggableSection } from 'Client/components/organisms';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { DemographicsQuestionRenderer } from 'Client/pages/demographics/components';
import { SpecialQuestionsContainerProps } from './types';

export const SpecialQuestionsContainer: React.FC<
  SpecialQuestionsContainerProps
> = ({
  title,
  description,
  questions,
  onCurrentEditItem,
  snapshotDrop,
  nonSpecialTotal,
  benchmarkQuestions,
}: SpecialQuestionsContainerProps) => {
  const { t } = useTranslation();
  const isBenchmark = (id?: string): boolean => {
    return benchmarkQuestions
      ? benchmarkQuestions?.some(
          (benchmarkQuestion) => benchmarkQuestion.questionId === id
        )
      : false;
  };

  return (
    <Wrapper>
      <WithPadding>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <HtmlDescription typographyProps={{ variant: 'body1' }}>
          {description}
        </HtmlDescription>
      </WithPadding>
      <QuestionsWrapper>
        {questions
          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((q, index) => {
            if (q.content) {
              const { category, explanation, ...restContent } = q.content;
              const specialQuestionIndex = nonSpecialTotal + index;

              return (
                <DraggableSection
                  key={`${q.questionId}-${specialQuestionIndex}`}
                  id={`${q.questionId}-${specialQuestionIndex}`}
                  index={specialQuestionIndex}
                  isDraggingOver={snapshotDrop.isDraggingOver}
                >
                  <div
                    key={specialQuestionIndex}
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCurrentEditItem(
                        EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION,
                        index,
                        isBenchmark(q.questionId)
                      );
                    }}
                  >
                    <DemographicsQuestionRenderer
                      key={q.questionId}
                      category={category}
                      type={restContent.type}
                      infoTip={explanation}
                      question={{ id: q.questionId, ...restContent }}
                      handleChange={(e) => e}
                    />
                  </div>
                </DraggableSection>
              );
            }
            return (
              <span key={q.questionId} data-questionid={q.questionId}>
                <i>{t('Question content could not be fetched.')}</i>
              </span>
            );
          })}
      </QuestionsWrapper>
    </Wrapper>
  );
};
