import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { OrganisationEditor } from '../../../Editor';
import { CommonEditorProps } from './types';

export const RenderOrganisationEditor = ({
  handleContentUpdate,
  index,
}: CommonEditorProps) => {
  const [{ projectTeamPage }] = useEditModeContext();
  if (!projectTeamPage) return null;
  const {
    content: { stakeholders },
  } = projectTeamPage;
  const content = stakeholders[index - 1]; //removes header as first index
  if (!content) return null;
  return (
    <OrganisationEditor
      content={content}
      onContentUpdate={handleContentUpdate}
      index={index - 1}
      key={`${index}-project-team-organisation-key`}
    />
  );
};
