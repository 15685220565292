import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { DraggableSection, NavigationalMap } from 'Organisms';
import {
  HeroContent,
  NavigationalMapContent,
  ProgrammeContent,
} from 'Client/pages/hub/types';
import {
  Hero,
  Milestones,
  News,
  ProjectTeam,
  Proposals,
  Section,
  DynamicProposals,
  ContactTeam,
} from 'Client/pages/hub/components';
import { useEditModeContext, useProject } from 'Client/utils/hooks';
import { ProgrammeWrapper } from 'Client/pages/hub/components/Programme';
import { HtmlDescription } from 'Client/components/molecules';
import { ImageMapPro } from 'Client/components/molecules/ImageMapPro/ImageMapPro';
import { TwoColumnsVisualizer } from 'Client/pages/proposals/components/TwoColumns/TwoColumnsVisualizer';
import { ImageComparison } from 'Client/components/organisms/ImageComparison';
import AccordionVisualizer from 'Client/pages/proposals/components/Accordion/AccordionVisualizer';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../../constants';
import { EditPageProps } from '../../types';

interface HubViewProps extends Partial<EditPageProps> {
  onCurrentEditItem: (type: string, index: number) => void;
}

export const HubView: React.FC<HubViewProps> = ({
  proposals,
  newsPosts,
  milestones,
  stakeholders,
  onCurrentEditItem,
  ...props
}: HubViewProps) => {
  const [{ hubPage }] = useEditModeContext();
  const { t } = useTranslation('customer');
  const project = useProject();

  if (!hubPage || !hubPage.content) {
    return (
      <div dir="ltr">
        {t(
          'There is no hub page for this language, please add one in the database.'
        )}
      </div>
    );
  }
  if (hubPage.content && !hubPage.content.sections) {
    return (
      <div dir="ltr">
        {t('There are no sections for the hub page, please add one.')}
      </div>
    );
  }
  const hero = hubPage.content.sections.find(
    (section) => section.type === EDITABLE_CONTENT_BLOCK_TYPES.HERO
  );
  const heroContent = hero?.content as HeroContent;
  const programmeSection = hubPage.content.sections?.find(
    (section) => section.type === 'programme'
  ) as unknown as ProgrammeContent;

  const sectionComponentMap = {
    hero: (
      <Hero
        title={heroContent?.title}
        hasNews={project?.features?.news}
        description={heroContent?.description}
        learnMoreProps={{
          href: heroContent?.learnMore?.link?.url,
          text: heroContent?.learnMore?.link?.text,
        }}
        layout={heroContent?.layout}
        backgroundImage={heroContent?.backgroundImage}
        {...props}
      />
    ),
    news: <News newsPosts={newsPosts} isHub />,
    proposals: <Proposals proposals={proposals} />,
    milestones: <Milestones milestones={milestones} />,
    team: <ProjectTeam stakeholders={stakeholders} />,
    programme: <ProgrammeWrapper content={programmeSection?.content} />,
  };

  const mapSectionComponent = (type: string, index: number) => {
    const matchingSection = hubPage.content.sections[index];
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.TEXT) {
      return <HtmlDescription>{matchingSection.content.html}</HtmlDescription>;
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME) {
      return <ProgrammeWrapper content={matchingSection?.content} />;
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS) {
      return <DynamicProposals content={matchingSection?.content} />;
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO) {
      return <ImageMapPro content={matchingSection?.content} isEditMode />;
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP) {
      return (
        <div
          style={{ pointerEvents: 'none' }}
          onClickCapture={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCurrentEditItem(
              EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP,
              index
            );
          }}
        >
          <NavigationalMap
            content={matchingSection?.content as NavigationalMapContent}
          />
        </div>
      );
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM) {
      return <ContactTeam content={matchingSection?.content} />;
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS) {
      return (
        <TwoColumnsVisualizer
          left={{ content: matchingSection?.content?.left.content ?? '' }}
          right={{ content: matchingSection?.content?.right.content ?? '' }}
        />
      );
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON) {
      return (
        <ImageComparison imageComparisonSlider={matchingSection?.content} />
      );
    }
    if (type === EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION) {
      return (
        <AccordionVisualizer accordionContent={matchingSection?.content} />
      );
    }
    return sectionComponentMap[type];
  };

  return (
    <Droppable droppableId="existing-hub-sections">
      {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
        <div ref={providedDrop.innerRef} data-testid="HubView">
          {hubPage.content.sections.map((section, index) => {
            if (!section.active) return null;

            return (
              <DraggableSection
                key={`span:${section.type}-${index}`}
                id={`span:${section.type}-${index}`}
                index={index}
                isDraggingOver={snapshotDrop.isDraggingOver}
              >
                <div
                  data-testid={'hub-block-item'}
                  data-scroll-id={section?._id}
                >
                  <span
                    key={`span:${section.type}-${index}`}
                    data-testid={`span:${section.type}-${index}`}
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCurrentEditItem(section.type, index);
                    }}
                  >
                    <Section
                      key={`section:${section.type}-${index}`}
                      type={section.type}
                      Component={mapSectionComponent(section.type, index)}
                    />
                  </span>
                </div>
              </DraggableSection>
            );
          })}
        </div>
      )}
    </Droppable>
  );
};
