import React from 'react';
import { ProgrammeContent } from 'Client/pages/hub/types';
import { ProgrammeEditor } from '../../../Editor';
import { HubEditorProps } from './types';

export const RenderProgrammeEditor = ({
  hubPageContent,
  handleContentUpdate,
  index,
}: HubEditorProps) => {
  if (!hubPageContent) return null;
  const {
    content: { sections },
  } = hubPageContent;
  const programmeItem = sections[index];
  const programmeContent = programmeItem as unknown as ProgrammeContent;
  return (
    <ProgrammeEditor
      progammeUpdate={handleContentUpdate}
      programme={programmeContent}
      index={index}
    />
  );
};
