import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { MilestoneEditor } from '../../../Editor';
import { CommonEditorProps } from './types';

export const RenderMilestoneEditor = ({
  handleContentUpdate,
  index,
}: CommonEditorProps) => {
  const [{ milestonesBlocks }] = useEditModeContext();
  if (!milestonesBlocks[index]) return null;
  const { data } = milestonesBlocks[index];
  const { content } = data;
  return (
    <MilestoneEditor
      milestone={content}
      onContentUpdate={handleContentUpdate}
    />
  );
};
