import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DraggableSection } from 'Client/components/organisms';
import { DemographicsQuestionRenderer } from 'Client/pages/demographics/components';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { QuestionsContainerProps } from './types';

export const QuestionsContainer: React.FC<QuestionsContainerProps> = ({
  questions,
  onCurrentEditItem,
  snapshotDrop,
  benchmarkQuestions,
}: QuestionsContainerProps) => {
  const { t } = useTranslation();
  const isBenchmark = (id?: string): boolean => {
    return benchmarkQuestions
      ? benchmarkQuestions?.some(
          (benchmarkQuestion) => benchmarkQuestion.questionId === id
        )
      : false;
  };
  return (
    <Wrapper>
      <div>
        {questions
          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((q, index) => {
            if (q.content) {
              const { category, explanation, ...restContent } = q.content;

              return (
                <DraggableSection
                  key={`${q.questionId}-${index}`}
                  id={`${q.questionId}-${index}`}
                  index={index}
                  isDraggingOver={snapshotDrop.isDraggingOver}
                >
                  <span
                    key={index}
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCurrentEditItem(
                        EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION,
                        index,
                        isBenchmark(q.questionId)
                      );
                    }}
                  >
                    <DemographicsQuestionRenderer
                      key={q.questionId}
                      category={category}
                      type={restContent.type}
                      infoTip={explanation}
                      question={{ id: q.questionId, ...restContent }}
                      handleChange={(e) => e}
                    />
                  </span>
                </DraggableSection>
              );
            }
            return (
              <i key={q.questionId} data-questionid={q.questionId}>
                {t('Question content could not be fetched.')}
              </i>
            );
          })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 1.5rem;
  min-height: 3.125rem; /*Need to exist in order to have 'where' to drop questions*/
  & > h3 {
    margin-bottom: 0.3125rem;
  }
`;
