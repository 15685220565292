import * as React from 'react';
import { HubContent } from 'Client/pages/hub/types';
import {
  EDITABLE_CONTENT_BLOCK_TYPES,
  editableBlockTypes,
} from '../../constants';
import {
  EditPageProps,
  SelectedEditItem,
  SelectedViewOption,
} from '../../types';
import { EditPanel } from '../EditPanel/EditPanel';
import { Content } from './RightPanel.styles';
import { Summary } from '../Summary/Summary';

interface Props extends Partial<EditPageProps> {
  currentEditItem: SelectedEditItem;
  editablePages: Array<SelectedViewOption>;
  hubPageState?: HubContent;
  handleContentUpdate: (content: unknown) => void;
  currentView: SelectedViewOption;
  onCurrentEditItem: (t: string, i: number | null) => void;
}

export const RightPanel: React.FC<Props> = ({
  currentEditItem,
  editablePages,
  revisions,
  hubPageState,
  handleContentUpdate,
  currentView,
  onCurrentEditItem,
  proposals,
}: Props) => {
  const { index, type, isBenchmark, locked } = currentEditItem;
  const isEditPanel = editableBlockTypes.includes(
    currentEditItem.type as EDITABLE_CONTENT_BLOCK_TYPES
  );
  return (
    <Content>
      {isEditPanel ? (
        <EditPanel
          hubPageContent={hubPageState}
          currentView={currentView}
          index={index}
          type={type as EDITABLE_CONTENT_BLOCK_TYPES}
          handleContentUpdate={handleContentUpdate}
          onCurrentEditItem={onCurrentEditItem}
          editablePages={editablePages}
          isBenchmark={isBenchmark}
          locked={locked}
          proposals={proposals}
        />
      ) : (
        <Summary
          revisions={revisions}
          currentView={currentView}
          editablePages={editablePages}
        />
      )}
    </Content>
  );
};
