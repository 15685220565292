import * as React from 'react';
import { useTranslation } from 'react-i18next';
import MuiModal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/node';
import { CloseIcon, CircleCheckFilledIcon } from 'Atoms/Icons';
import { useProject } from 'Client/utils/hooks';
import { LoadRing } from 'Client/components/molecules';
import { RootState } from 'Client/redux-store';
import { theme } from 'Client/components/theme';
import { CustomerProjectPageSelector } from 'Client/components/organisms/CustomerProjectPageSelector/customerProjectPageSelector';
import {
  ButtonsSection,
  CancelButton,
  DescriptionSection,
  DoneButton,
  ImportButton,
  LoadingWrapper,
  ModalHeader,
  SuccessWrapper,
  Wrapper,
} from './DuplicateTileModal.styles';
import { DUPLICATE_PROPOSAL_CONTENT } from './duplicateProposalContent.gql';

interface DuplicateTileModalProps {
  open: boolean;
  onClose: () => void;
  pageTypes: string[];
}

export const DuplicateTileModal = ({
  open,
  onClose,
  pageTypes,
}: DuplicateTileModalProps) => {
  const { t } = useTranslation('customer');
  const router = useRouter();
  const project = useProject();

  const [selectedPage, setSelectedPage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [duplicateProposalContent] = useMutation(DUPLICATE_PROPOSAL_CONTENT);

  const proposalViewRdx = useSelector(
    (state: RootState) => state.editModeProposalView
  );

  const handleClose = () => {
    setSelectedPage(null);
    onClose();
  };
  const handleSuccessClick = () => router.reload();

  const handleImport = async () => {
    try {
      setLoading(true);
      const res = await duplicateProposalContent({
        variables: {
          duplicateContentInput: {
            newProjectId: project._id,
            newPageId: proposalViewRdx[router.locale]._id,
            originalPageId: selectedPage._id,
          },
        },
      });
      setSuccess(res?.data?.duplicateContent?.success);
    } catch (e) {
      captureException(e);
    } finally {
      setLoading(false);
    }
  };

  const SuccessScreen: React.FC = () => (
    <SuccessWrapper>
      <CircleCheckFilledIcon
        fill={theme.colors.green['A700']}
        width={72}
        height={72}
      />
      <p>{t('It was added at the end of your content, after a page break.')}</p>
      <ButtonsSection>
        <DoneButton onClick={handleSuccessClick}>Done</DoneButton>
      </ButtonsSection>
    </SuccessWrapper>
  );

  return (
    <MuiModal open={open} onClose={handleClose} onBackdropClick={handleClose}>
      <Wrapper isSuccess={success}>
        {loading ? (
          <LoadingWrapper>
            <LoadRing />
            <p>{t('Loading')}</p>
          </LoadingWrapper>
        ) : (
          <>
            <ModalHeader>
              <h2>{t('Import content from another tile')}</h2>
              <button
                onClick={handleClose}
                data-testid="modal-close-button"
                tabIndex={0}
                disabled={loading}
              >
                <CloseIcon />
                <Typography variant="srOnly">
                  {t('Close modal button')}
                </Typography>
              </button>
            </ModalHeader>
            {success ? (
              <SuccessScreen />
            ) : (
              <>
                <DescriptionSection>
                  <p>
                    {t(
                      'Select the project and the tile that you want to import content from. This content will be added at the end of your current tile, after a page break.'
                    )}
                  </p>
                </DescriptionSection>
                <CustomerProjectPageSelector
                  pageTypes={pageTypes}
                  setSelectedPage={setSelectedPage}
                />

                <ButtonsSection>
                  <CancelButton onClick={handleClose}>
                    {t('Cancel')}
                  </CancelButton>
                  <ImportButton disabled={!selectedPage} onClick={handleImport}>
                    {t('Import')}
                  </ImportButton>
                </ButtonsSection>
              </>
            )}
          </>
        )}
      </Wrapper>
    </MuiModal>
  );
};
