import * as React from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export const Panel = styled.div`
  background: ${({ theme: { colors } }) => colors.white[500]};
  height: 100%;
`;

export const EditPanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  height: 3rem;
  & > h1 {
    color: ${({ theme }) => theme.colors.black[500]} !important;
  }
`;

export const QuestionTabWrapper = styled.div<{ activeTab: boolean }>`
  ${({ activeTab }) => css`
    ${activeTab
      ? ''
      : css`
          display: none;
        `}
    height: calc(
          100% - 3rem - 2.8125rem
        ); // 3 rem of the header, 2.8125rem for the customize tab
  `}
`;

export const Content = styled(Card)`
  height: 100%;
  box-shadow: 0 0 0.25rem rgb(0 0 0 / 25%);
`;

export const EditorPanel = styled(({ ...props }) => <div {...props} />)`
  height: fit-content;

  ${({ isFroalaLoaded }) =>
    isFroalaLoaded &&
    css`
      & > .fr-box.fr-basic {
        height: 100%;
      }

      & > .fr-box.fr-basic.fr-top .fr-wrapper {
        height: 70%;
        overflow-y: auto;
      }
    `}
`;

export const CardEditorWrapper = styled(CardContent)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.colorMappings.editModePrimaryColor};
  }
  &:hover {
    cursor: pointer;
    .MuiSvgIcon-root {
      fill: ${({ theme }) =>
        darken(0.2, theme.colorMappings.editModePrimaryColor)};
    }
  }
`;

export const TabSelector = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid ${theme.colors.grey[300]};
  `}
`;

export const TabOption = styled.span<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    margin: 0;
    color: ${theme.colors.black[50]};
    font-size: 1.125rem;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      border-bottom: 2px solid ${lighten(0.1, theme.colorMappings.editModeBlue)};
    }
    ${selected &&
    css`
      color: ${theme.colorMappings.editModeBlue};
      border-bottom: 2px solid ${theme.colorMappings.editModeBlue};
    `}
  `}
`;

export const LockedEditing = styled.div`
  padding: 1rem;
`;
