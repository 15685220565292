/**
 * Get a cropped screenshot from the current map. Useful for map previews saved in CDN.
 *
 * @returns The cropped area as a canvas.
 */
const getMapScreenshot = (
  mapCanvas: HTMLCanvasElement,
  width: number,
  height: number
): HTMLCanvasElement => {
  const crop = document.createElement('canvas');
  crop.width = width;
  crop.height = height;
  crop
    .getContext('2d')
    ?.drawImage(
      mapCanvas,
      width / 2 - mapCanvas.width / 2,
      height / 2 - mapCanvas.height / 2
    );
  return crop;
};

export default getMapScreenshot;
