import fetch from 'isomorphic-unfetch';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ChevronDownIcon } from 'Icons';
import { PreventRouteModal } from 'Client/components/organisms';
import { RevisionItem } from './RevisionItem';
import { Revision } from '../../types';
import { RevisionBlockProps } from './types';
import { ViewMore, Item } from './Revision.styles';

export const RevisionsBlock: React.FC<RevisionBlockProps> = ({
  revisions,
  currentView,
}: RevisionBlockProps) => {
  const { t } = useTranslation('customer');
  const router = useRouter();
  const [hoverIndex, setHoverIndex] = React.useState<number>(null);
  const [viewAll, setViewAll] = React.useState<boolean>(false);
  const [isVersionModalOpen, setIsVersionModalOpen] =
    React.useState<boolean>(false);
  const [versionId, setVersionId] = React.useState<string>('');
  const [revisionsList, setRevisionsList] =
    React.useState<Array<Revision>>(revisions);

  const isCurrentVersion = (index: number): boolean => index === 0;

  const handleOpenVersionModal = (id: string) => {
    setIsVersionModalOpen(true);
    setVersionId(id);
  };

  const handleSwitchVersion = async () => {
    if (!versionId) {
      setIsVersionModalOpen(true);
      return;
    }

    await fetch(`/api/pages/version/${currentView?.pageId}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        lang: router.locale,
        versionId,
      }),
    });

    window.location.reload();
  };

  React.useEffect(() => {
    if (viewAll) {
      setRevisionsList(revisions);
    } else {
      const smallerRevisions = revisions.slice(0, 3);
      setRevisionsList(smallerRevisions);
    }
  }, [revisions, viewAll]);

  return (
    revisionsList.length > 0 && (
      <>
        {revisionsList.map((revision, index) => (
          <Item
            key={revision._id}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
            isCurrentVersion={isCurrentVersion(index)}
          >
            <RevisionItem
              _id={revision._id}
              timeStamp={revision.timeStamp}
              isCurrentVersion={isCurrentVersion(index)}
              isItemHovered={hoverIndex === index}
              onSwitchVersion={handleOpenVersionModal}
              type={currentView.type}
            />
          </Item>
        ))}
        <ViewMore onClick={() => setViewAll(!viewAll)}>
          <span>{viewAll ? t('View less') : t('View more')}</span>
          <ChevronDownIcon isExpanded={!viewAll} />
        </ViewMore>

        <PreventRouteModal
          open={isVersionModalOpen}
          onClose={() => setIsVersionModalOpen(false)}
          customText={{
            description: t('Are you sure?'),
            header: t(
              "You're about to switch to another version and refresh the page."
            ),
            save: t('Yes, switch version'),
            cancel: t('No, cancel'),
          }}
          onDiscard={() => setIsVersionModalOpen(false)}
          onSave={handleSwitchVersion}
        />
      </>
    )
  );
};
