import * as React from 'react';
import MuiCloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useEditModeContext, usePermissions } from 'Client/utils/hooks';
import { Title } from 'Client/templates/ProposalPages/components/Masthead/Masthead.styles';
import { Permissions } from 'Client/constants/permissions';
import { EDITABLE_BLOCKS_TITLE, EDITABLE_PAGE_TYPES } from '../../constants';
import {
  Panel,
  Content,
  EditorPanel,
  CardEditorWrapper,
  CloseButton,
  EditPanelHeader,
  TabSelector,
  TabOption,
  QuestionTabWrapper,
  LockedEditing,
} from './EditPanel.styles';
import { RenderEditor } from './EditorRenderer';
import { EditPanelProps } from './types';
import CodeViewAlert from '../Editor/components/CodeViewAlert/CodeViewAlert';
import CustomizeTab from './CustomizeTab/CustomizeTab';

export const EditPanel: React.FC<EditPanelProps> = ({
  hubPageContent,
  type,
  handleContentUpdate,
  index,
  currentView,
  onCurrentEditItem,
  editablePages,
  isBenchmark,
  proposals,
  locked,
}: EditPanelProps) => {
  const { t } = useTranslation('customer');
  const { can } = usePermissions();

  const [{ metadata, proposalBlocks }] = useEditModeContext();
  const [isCodeViewActive, setIsCodeViewActive] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('question');
  const handleCloseEditPanel = () => {
    onCurrentEditItem('', null);
  };
  const userCanSeeCustomiseTab = can(Permissions.SEE_CUSTOMISE_TAB);
  const isProposalEditMode = currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL;
  const seeCustomiseTab = userCanSeeCustomiseTab && isProposalEditMode;

  const block = proposalBlocks[index];
  const blockType = block ? block?.data?.content?.type || '' : '';

  return (
    <Panel data-onboarding="edit-mode-edit-section">
      <Content>
        <EditPanelHeader
          data-onboarding={`edit-mode-${type}${
            blockType ? `-${blockType}` : ''
          }-title`}
        >
          <Title>{EDITABLE_BLOCKS_TITLE?.[type] || 'Edit'}</Title>
          <CloseButton
            onClick={handleCloseEditPanel}
            data-testid="edit-panel-close-button"
          >
            <MuiCloseIcon data-onboarding="edit-mode-close-edit-section" />
          </CloseButton>
        </EditPanelHeader>
        {seeCustomiseTab && (
          <TabSelector data-testid="edit-mode-block-tab-selector">
            <TabOption
              onClick={() => setActiveTab('question')}
              selected={activeTab === 'question'}
              data-testid="edit-mode-tab-selector-question"
            >
              {t('Settings')}
            </TabOption>
            <TabOption
              onClick={() => setActiveTab('customize')}
              selected={activeTab === 'customize'}
              data-testid="edit-mode-tab-selector-customize"
            >
              {t('Customise')}
            </TabOption>
          </TabSelector>
        )}
        {locked ? (
          <LockedEditing>
            <p>
              <i>{t('This block cannot be edited.')}</i>
            </p>
          </LockedEditing>
        ) : (
          <>
            <QuestionTabWrapper activeTab={activeTab === 'question'}>
              {isCodeViewActive && <CodeViewAlert />}
              <CardEditorWrapper>
                <EditorPanel
                  isFroalaLoaded={metadata.isFroalaLoaded}
                  data-testid="editPanelComponent"
                >
                  <RenderEditor
                    type={type}
                    index={index}
                    handleContentUpdate={handleContentUpdate}
                    currentView={currentView}
                    editablePages={editablePages}
                    isBenchmark={isBenchmark}
                    hubPageContent={hubPageContent}
                    setIsCodeViewActive={setIsCodeViewActive}
                    proposals={proposals}
                  />
                </EditorPanel>
              </CardEditorWrapper>
            </QuestionTabWrapper>
            {seeCustomiseTab && (
              <CustomizeTab
                index={index}
                type={type}
                activeTab={activeTab === 'customize'}
                onContentUpdate={handleContentUpdate}
              />
            )}
          </>
        )}
      </Content>
    </Panel>
  );
};
