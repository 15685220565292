import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import PencilIcon from '@material-ui/icons/CreateOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { Typography, InputLabel } from 'Atoms';
import { TextField } from 'Molecules';
import { NoSSRFroala } from '../Editor';
import { EDITABLE_PAGE_TYPES } from '../../constants';
import { EditImageSectionProps } from './types';

export const EditImageSection: React.FC<EditImageSectionProps> = ({
  cardValue,
  froalaImage,
  onImageContentUpdate,
  onImageTextChange,
}: EditImageSectionProps) => {
  const { t } = useTranslation('customer');
  const [showImageEditor, setShowImageEditor] = React.useState(false);

  return (
    <Box my={1}>
      {showImageEditor ? (
        <>
          <ImageTextIcon
            icon="close"
            label={t(`Hide edit image section`)}
            handleClick={() => setShowImageEditor(false)}
          />
          <Box mb={1}>
            <InputLabel>{t('Image:')}</InputLabel>
            <NoSSRFroala
              isImageEditor
              content={froalaImage}
              onContentUpdate={onImageContentUpdate}
              currentView={{
                type: EDITABLE_PAGE_TYPES.SUMMARY,
                label: 'summary',
                value: 'summary',
              }}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={t('Image title')}
              showLabel
              value={cardValue?.image?.title || ''}
              handleChange={(e) => onImageTextChange('title', e.target.value)}
            />
          </Box>
          <Box mb={1}>
            <TextField
              label={t('Image alt text')}
              showLabel
              value={cardValue?.image?.alt || ''}
              handleChange={(e) => onImageTextChange('alt', e.target.value)}
            />
          </Box>
        </>
      ) : (
        <ImageTextIcon
          icon="edit"
          label={t(`Change image`)}
          handleClick={() => setShowImageEditor(true)}
        />
      )}
    </Box>
  );
};

const ImageTextIcon = ({ label, icon, handleClick }) => (
  <ImageText
    color="primary"
    variant="body2"
    onClick={handleClick}
    data-testid={
      icon === 'edit' ? 'change-image-option' : 'hide-edit-image-option'
    }
  >
    {icon === 'edit' ? (
      <PencilIcon
        fontSize="small"
        style={{ verticalAlign: 'bottom', marginRight: '1rem' }}
      />
    ) : (
      <CloseIcon
        fontSize="small"
        style={{ verticalAlign: 'bottom', marginRight: '1rem' }}
      />
    )}
    {label}
  </ImageText>
);

const ImageText = styled((props) => <Typography {...props} />)`
  font-weight: 700;
  ${({ theme }) => css`
    color: ${theme.colorMappings.editModePrimaryColor};
    svg {
      fill: ${theme.colorMappings.editModePrimaryColor};
    }
  `};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
