import { darken, readableColor } from 'polished';
import styled, { css } from 'styled-components';
import { Switch } from 'Client/components/molecules';
import { Dropdown } from '../../Form';

export const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 100%;
    margin-left: -2rem;
  }
`;
export const NavItem = styled.div<{ firstItem: boolean }>`
  width: calc(100% + 1rem * 2);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  ${({ firstItem }) =>
    firstItem &&
    css`
      & div > div > svg:nth-of-type(1) {
        visibility: hidden;
      }
      & > div > div > label .MuiFormControlLabel-root {
        visibility: hidden;
      }
    `}
`;

export const EditModeButton = styled.button<{
  inverse?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, inverse, disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: auto;
    padding: 0.625rem 1rem;
    min-height: unset;
    border-radius: 0.1875rem;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${inverse
      ? theme.colors.white[500]
      : theme.colorMappings.greyTextDarker};
    &:hover {
      color: ${darken(0.2, theme.colorMappings.greyTextDarker)};
    }
    border: 1px solid ${theme.colorMappings.greyButtonBorder};
    background-color: ${theme.colorMappings.white};

    ${inverse &&
    css`
      background-color: ${theme.colorMappings.editButtonBackground};
      border: 1px solid ${theme.colorMappings.editButtonBackground};
      &:hover {
        color: ${readableColor(
          darken(0.1, theme.colorMappings.editButtonBackground)
        )};
        background-color: ${darken(
          0.1,
          theme.colorMappings.editButtonBackground
        )};
        border: 1px solid
          ${darken(0.1, theme.colorMappings.editButtonBackground)};
      }
    `}
  `}
`;

export const AddItemButton = styled(EditModeButton)`
  padding: 0.8rem 1.25rem;
  margin-top: 1rem;
  font-weight: bold;
`;

export const AccordionHeader = styled.div<{ isEditing?: boolean }>`
  ${({ theme, isEditing }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    padding: 1.2rem 0.5rem;
    background: ${theme.colorMappings.white};
    border-top: 0.0625rem solid ${theme.colors.grey[200]};
    ${!isEditing &&
    css`
      border-bottom: 0.0625rem solid ${theme.colors.grey[200]};
    `}
  `}
`;

export const StyledSwitch = styled(Switch)`
  margin: 0;
  & > span {
    margin: 0;
  }
`;

export const DraggableContainerSlim = styled(NavItem)`
  padding: 0rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DraggableContainerSlimActive = styled(DraggableContainerSlim)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
`;

export const AccordionLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const AccordionRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const AccordionContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1rem 2rem;
    padding-top: 0;
    & > div > h3 {
      margin: 0;
    }
    border-bottom: 0.0625rem solid ${theme.colors.grey[200]};
  `}
`;

export const AccordionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  & > div {
    display: flex;
    gap: 1rem;
    flex-direction: row;
  }
`;

export const PagesDropdown = styled(Dropdown)`
  ${({ theme }) => css`
    .react-select__control {
      border-radius: 0.5rem;
      border-color: ${theme.colorMappings.inputBorder};
      color: #000;
      &::placeholder {
        color: ${theme.colorMappings.greyPlaceholderColor};
      }
    }
    .react-select__indicators {
      background-color: #f8f8f8;
      width: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0.5rem 0.5rem 0;
    }
    .react-select__single-value > .MuiListItemText-root > span {
      color: #000 !important;
    }
  `}
`;
