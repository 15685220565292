import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { ProjectGaudi } from 'Shared/types';
import { Contribution } from 'Shared/types/contribution';
import { fetchUserByEmail } from '../user';

export const createContribution = async (
  data: Partial<Contribution>,
  ignoreGaming: boolean = false
): Promise<Contribution> => {
  try {
    const created = await fetch(`/api/contributions`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, ...{ ignoreGaming: ignoreGaming } }),
    }).then(async (res) => await res.json());

    const project = (await fetch(`/api/projects/${data.projectId}`).then(
      (res) => res
    )) as ProjectGaudi;
    if (project?.features?.trackContributionFlow) {
      const user = await fetchUserByEmail(created?.data?.userId);
      mixpanel(user, project, 'en-GB').trackEvent(
        MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW,
        {
          fileName: 'src/client/services/contributions/createContribution.ts',
          functionName: 'createContribution',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(created.data),
          gaudiUpdate: null,
          acornUpdate: created?.data as unknown as Record<string, unknown>,
          userId: created?.data.userId,
          demographicsId: created?.data?.demographicsId,
          contributionId: created?.data._id,
        }
      );
    }
    return created.data;
  } catch (err) {
    captureException(err);
    return null;
  }
};
