import * as React from 'react';
import styled from 'styled-components';
import { Button as ButtonBase } from 'Molecules';
import { CloseIcon } from 'Atoms/Icons';

export const Icon = styled((props) => <CloseIcon {...props} />)`
  margin-right: 0.625rem;
`;

export const Button = styled((props) => <ButtonBase {...props} />)`
  background-color: ${({ theme }) => theme.colorMappings.editButtonBackground};
  border-radius: 2rem;
  border: 0;
  padding: 0.9375rem;
  width: 9.375rem;
  height: 2.8125rem;
  color: ${({ theme }) => theme.colors.white[500]};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  left: 36rem;
  top: 9.125rem;
  z-index: 1;
  display: flex;
  justify-content: space-around;

  &:hover {
    color: ${({ theme }) => theme.colors.white[500]};
    background-color: ${({ theme }) =>
      theme.colorMappings.editButtonBackground};
    border: 0;
  }
`;
