import styled, { css } from 'styled-components';

export const CustomizeTabWrapper = styled.div<{ activeTab: boolean }>`
  ${({ activeTab }) => css`
    padding: 1rem;
    ${!activeTab && 'display: none'}
  `}
`;

export const ColorItem = styled.span<{
  color: string;
  selected?: boolean;
  customSize?: { width: string; height: string };
}>`
  ${({ theme, color, customSize, selected }) => css`
    background-color: ${color};
    border: 2px solid ${selected ? '#7E7E7E' : theme.colors.grey[300]};
    border-radius: 0.25rem;
    &:hover {
      cursor: pointer;
      border: 2px solid #bbb;
    }
    ${customSize
      ? css`
          width: ${customSize.width};
          height: ${customSize.height};
        `
      : css`
          width: 2rem;
          height: 2rem;
        `}
  `}
`;

export const ItemsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
    align-items: flex-start;
    position: relative;
    & > p {
      color: ${theme.colors.black[500]} !important;
    }
  `}
`;

export const ItemSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > p {
    margin: 0;
  }
`;

export const ColourSelector = styled(ItemSection)`
  width: 3.75rem;
  align-items: center;
  & > span {
    margin-top: 0.5rem;
  }
`;

export const AvailableColoursPopup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 0.25rem;
  width: 6.4375rem;
  height: fit-content;
  padding: 0.5rem;
  border-radius: 0.4375rem;
  border: 0.8px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100px;
`;
