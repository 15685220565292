import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Link = styled.a`
  font-weight: bold;
  margin-left: 0.5rem;
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.colorMappings.accessibleBrand};
  `}
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 90%;
`;

export const Message = styled(Typography)`
  font-size: 1rem;
`;
