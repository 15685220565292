import create from 'zustand';
// TODO: Replace state mgmt depenancy with team agreed approach

type MapFeatureFormValues = {
  title: string;
  description: string;
  imageUrl: string;
  proposal?: { id: string };
  hoverablePopup: boolean;
  isPlanningApp: boolean;
  landmark?: string;
};

type MapFeatureFormActions = {
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setImageUrl: (imageUrl: string) => void;
  setProposal: (proposal: { id: string }) => void;
  setHoverablePopup: (hoverablePopup: boolean) => void;
  setIsPlanningApp: (isPlanningApp: boolean) => void;
  setLandmark: (landmark: string) => void;
};

export type MapFeatureFormState = MapFeatureFormValues & MapFeatureFormActions;

const initialState: MapFeatureFormValues = {
  description: '',
  title: '',
  imageUrl: '',
  hoverablePopup: false,
  isPlanningApp: false,
  landmark: null,
};

export const useMapFeatureForm = create<MapFeatureFormState>((set) => ({
  ...initialState,
  setDescription: (description) => set({ description }),
  setTitle: (title) => set({ title }),
  setImageUrl: (imageUrl) => set({ imageUrl }),
  setProposal: (proposal) => set({ proposal }),
  setHoverablePopup: (hoverablePopup) => set({ hoverablePopup }),
  setIsPlanningApp: (isPlanningApp) => set({ isPlanningApp }),
  setLandmark: (landmark) => set({ landmark }),
}));
