import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';

export const Title = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;
`;

export const TitleWrapper = styled.div`
  padding: 1rem 2rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ViewMore = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem 0;
  text-decoration: underline;
  :hover {
    cursor: pointer;
    color: ${({ theme }) =>
      darken(0.1, theme.colorMappings.editModePrimaryColor)};
    svg {
      color: ${({ theme }) =>
        darken(0.1, theme.colorMappings.editModePrimaryColor)} !important;
    }
  }
  svg {
    color: ${({ theme }) =>
      theme.colorMappings.editModePrimaryColor} !important;
    margin-left: 0.75rem;

    g {
      opacity: 1;
    }
  }
  ${({ theme }) => css`
    color: ${theme.colorMappings.editModePrimaryColor};
    font-size: ${theme.texts.secondaryText.fontSize};
    font-weight: ${theme.fontWeights.bold};
  `}
`;

export const Item = styled('li')<{ isCurrentVersion: boolean }>`
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  ${({ isCurrentVersion, theme }) =>
    !isCurrentVersion &&
    css`
      :hover {
        background: ${theme.colorMappings.editModeSelectedHighlight};
      }
    `}
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const CurrentVersion = styled(Text)`
  align-self: center;
  cursor: default;
  margin: 0.3125rem 0 0;
  font-size: 0.75rem;
  font-weight: 600;
  min-width: 7rem;
  font-style: italic;
  ${({ theme }) => `
    color: ${theme.colors.green['A700']};
  `}
`;

export const Content = styled.div`
  margin-right: 0.5rem;
`;

export const RevisionItemContainer = styled.div`
  padding: 1rem 2rem;
`;

export const Link = styled.a`
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
`;

export const DateText = styled.span`
  font-size: 0.875rem;
  font-weight: 700;
`;

export const VersionLinkContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;

  span {
    margin: 0 0.5rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('lg')} {
      p,
      span {
        font-size: 0.75rem;
      }

      span {
        margin: 0 0.25rem;
      }
    }
  `};
`;

export const VersionLink = styled.p`
  margin: 0;
  ${({ theme }) => css`
    font-size: ${theme.texts.secondaryText.fontSize};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colorMappings.editModePrimaryColor};
  `}
`;
