import React from 'react';
import { HeroContent } from 'Client/pages/hub/types';
import { HeroEditor } from '../../../Editor';
import { HubEditorProps } from './types';
export const RenderHeroEditor = ({
  hubPageContent,
  handleContentUpdate,
}: HubEditorProps) => {
  if (!hubPageContent) return null;
  const {
    content: { sections },
  } = hubPageContent;
  const heroItem = sections.find((s) => s.type === 'hero');
  const heroContent = heroItem.content as HeroContent;
  const data = {
    ...heroContent,
    link: heroContent.link,
    _id: heroItem?._id,
  };
  return <HeroEditor heroUpdate={handleContentUpdate} content={data} />;
};
