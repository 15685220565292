import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const SideSection = styled.div`
  width: 25%;
  height: 100%;
`;
export const MiddleSection = styled.div`
  width: calc(50% - 3rem);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled(Grid)`
  height: calc(100% - 4rem); // 4rem is the height of the toolbar
  margin: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  flex-direction: row;
  padding: 1rem;
  position: relative;
  justify-content: space-between;
`;

export const Processing = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  filter: blur(0rem) opacity(0.5);
  background: white;
  margin: 0rem auto;
`;
