import styled, { css } from 'styled-components';
import { darken } from 'polished';
import MuiPaper from '@material-ui/core/Paper';
import { Button } from 'Client/components/molecules';
import { Dropdown } from '../Form';

export const Wrapper = styled(MuiPaper)<{ isSuccess: boolean }>`
  ${({ theme, isSuccess }) => css`
    width: 32.5rem;
    height: ${isSuccess ? '18.75rem' : 'fit-content'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 1.875rem;
    color: ${theme.colors.black[50]};
  `};
`;

export const ModalHeader = styled.div`
${({ theme }) => css`
  display: flex;
  height: 25%;
  justify-content: space-between;
  padding: 1.5625rem 0;
  position: relative;
  & > h2 {
    margin: 0;
    font-size: 1.25rem;
  }
  & > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 3.75rem);
    left: -1.875rem;
    bottom: 0;
    border-top: 1px solid ${theme.colors.white['400']};
`}
  }
`;

export const DescriptionSection = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 0;
    & > p {
      margin: 0;
      font-size: 1.125rem;
      color: ${theme.colorMappings.descriptionTextColor};
    }
  `}
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ButtonsSection = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
    position: relative;
    & > button {
      border-radius: 0.2344rem;
      width: 5.3125rem;
      height: 2.5625rem;
      border: none;
      font-size: 0.9375rem;
    }
    &:after {
      content: '';
      position: absolute;
      width: calc(100% + 3.75rem);
      left: -1.875rem;
      top: 0;
      border-top: 1px solid ${theme.colors.white['400']};
      padding-bottom: 1rem;
    }
  `}
`;

export const CancelButton = styled(Button)`
  background: transparent;
  font-weight: 500;
  &:hover {
    background-color: ${darken(0.1, '#fff')};
    border: none;
  }
`;
export const ImportButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.green['A700']};
    color: white;
    &:hover {
      background-color: ${darken(0.1, theme.colors.green['A700'])};
      color: white;
      border: none;
    }
    &:focus {
      &:after {
        content: none;
      }
      border: 2.5px solid ${theme.colorMappings.primaryButtonFocus};
    }
  `}
`;

export const DoneButton = styled(ImportButton)`
  border-radius: 0.2344rem;
  width: 4.688rem;
  height: 2.5625rem;
  border: none;
  font-size: 0.9375rem;
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const RoundedDropdown = styled(Dropdown)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    ${disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      filter: opacity(0.5);
    `}
  `}
  .react-select__control {
    border-radius: 0.5rem;
    border-color: #e1e3e6;
    color: #000;
    &::placeholder {
      color: ${({ theme }) => theme.colorMappings.greyPlaceholderColor};
    }
  }
  .react-select__indicators {
    background-color: #f8f8f8;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0.5rem 0.5rem 0;
  }
  .react-select__single-value > .MuiListItemText-root > span {
    color: #000 !important;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
`;

export const SuccessWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 75%;
`;
