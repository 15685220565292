import React from 'react';
import { DemographicsQuestionsEditor } from '../../../Editor';
import { DemographicsQuestionEditorProps } from './types';

export const RenderDemographicsQuestionEditor = ({
  index,
  type,
  isBenchmark,
}: DemographicsQuestionEditorProps) => (
  <DemographicsQuestionsEditor
    key={`${index}-demographics-questions-${type}`}
    index={index}
    type={type}
    isBenchmark={isBenchmark}
  />
);
