import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useEditModeContext, useMap } from 'Client/utils/hooks';
import { update } from 'Client/utils/reduxReducers/editMode/proposalBlocksReducer';
import { OptionItem } from 'Client/types';
import {
  HUB_PAGE_ACTIONS,
  PROPOSAL_BLOCKS_ACTIONS,
} from 'Client/pages/edit/constants';
import { NavigationalMapContent } from 'Client/pages/hub/types';
import { NavmapEditorProps } from '../../../EditPanel/EditorRenderer/Editors/types';
import { Input, Label } from '../../../Form';
import InfoContainer from '../InfoContainer/InfoContainer';
import { InputWrapper, Select } from './NavmapForm.styles';

interface Props extends NavmapEditorProps {
  isProposal?: boolean;
  data?: NavigationalMapContent;
}

export const NavmapForm: React.FC<Props> = ({
  proposals,
  handleContentUpdate,
  isProposal,
  data,
  index,
}: Props) => {
  const { t } = useTranslation('customer');
  const {
    state: { xyz },
  } = useMap();
  const router = useRouter();
  const dispatchRdx = useDispatch();
  const [, { dispatchProposalBlocks }] = useEditModeContext();

  /* variables */
  const mapProposals =
    proposals?.filter((proposal) => proposal.type === 'map') || [];
  const mapOptions = mapProposals?.map((proposal) => ({
    label: proposal.content.card.title || proposal.slug,
    value: proposal.slug,
  }));

  /* state */
  const [title, setTitle] = useState<string>(data?.title || '');
  const [map, setMap] = useState<string>(data?.slug || '');

  /* effects */
  useEffect(() => {
    handleContentUpdate({
      type: isProposal
        ? PROPOSAL_BLOCKS_ACTIONS.UPDATE_PROPOSAL_MAP
        : HUB_PAGE_ACTIONS.UPDATE_NAV_MAP,
      content: {
        title,
        slug: map,
      },
      index,
      blockId: data._id,
    });
  }, [title, map]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setMap(data.slug);
    }
  }, [data]);

  /* handlers */
  const dispatchUpdate = (type: 'title' | 'map', value: string) => {
    const action = {
      type: PROPOSAL_BLOCKS_ACTIONS.UPDATE_PROPOSAL_MAP,
      content: {
        title: type === 'title' ? value : title,
        slug: type === 'map' ? value : map,
      },
      index,
    };
    dispatchProposalBlocks(action);
    dispatchRdx(update({ ...action, lang: router.locale }));
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    if (isProposal) {
      dispatchUpdate('title', e.target.value);
    }
  };

  const handleMapChange = (val: OptionItem<string> | null) => {
    if (xyz) {
      xyz.map.setTarget(null);
      xyz.map.dispose();
    }
    if (val) {
      setMap(val.value);
      if (isProposal) {
        dispatchUpdate('map', val.value);
      }
      return;
    }
    setMap('');
    if (isProposal) {
      dispatchUpdate('map', '');
    }
  };

  return (
    <>
      <InfoContainer alertIcon>
        <p>
          {t(
            'Please ensure that your map is set to active for it to show for respondents.'
          )}
        </p>
      </InfoContainer>

      <InputWrapper>
        <Label htmlFor="title" label={t('Title')} />
        <Input
          id="title"
          name="title"
          type="text"
          placeholder={t('About the project')}
          onChange={handleTitleChange}
          value={title}
        />
      </InputWrapper>

      <div>
        <Label htmlFor="map" label={t('Map')} />
        <Select
          options={mapOptions}
          isMulti={false}
          handleChange={handleMapChange}
          defaultValue={mapOptions?.find(
            (option) => option.value === data?.slug
          )}
        />
      </div>
    </>
  );
};
