import * as React from 'react';
import { useTheme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useEditModeContext, useProject } from 'Client/utils/hooks';
import { NavBarIcons } from 'Atoms/Icons/NavBar';
import { MOBILE_VIEW } from '../../constants';
import {
  SmartPhone,
  PhoneHeader,
  Content,
  BottomNavigation,
  NavPreview,
} from './MobilePreview.styles';

interface SmartphoneViewProps {
  children: React.ReactElement | React.ReactElement[];
  editModeDevice: 'mobile' | 'desktop';
}

const SmartphoneView: React.FC<SmartphoneViewProps> = ({
  children,
  editModeDevice = 'mobile',
}: SmartphoneViewProps) => {
  const theme = useTheme();
  const project = useProject();
  const router = useRouter();
  const [{ navbarState }] = useEditModeContext();
  const stateItems = navbarState[router.locale].items;
  const navbarV2 = !!project?.features?.navbarV2;
  const editModeDesktopZoomOut = true;
  const scaledDownLayout =
    editModeDevice === 'desktop' && editModeDesktopZoomOut;
  return (
    <SmartPhone
      data-testId="mobile-phone-preview-container"
      editModeDevice={editModeDevice}
    >
      {editModeDevice == 'mobile' && <PhoneHeader />}
      <Content
        scaledDownLayout={scaledDownLayout}
        editModeDevice={editModeDevice}
        dir={theme.direction === 'rtl' ? 'rtl' : 'ltr'}
      >
        {children}
      </Content>
      {editModeDevice == 'mobile' && (
        <>
          {navbarV2 ? (
            <NavPreview>
              {stateItems.map((item) => (
                <>{NavBarIcons[item.icon]}</>
              ))}
            </NavPreview>
          ) : (
            <BottomNavigation src={MOBILE_VIEW.bottomNavigationUrl} />
          )}
        </>
      )}
    </SmartPhone>
  );
};

export { SmartphoneView };
