import React from 'react';
import { useSelector } from 'react-redux';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { RootState } from 'Client/redux-store';
import { ProposalStage } from 'Client/pages/proposals/types';
import { useEditModeContext } from 'Client/utils/hooks';
import { ProjectStage } from 'Shared/types';
import { QuestionsEditorSelector } from '../../../Editor';
import { QuestionEditorProps } from './types';
export const RenderQuestionEditor = ({
  handleContentUpdate,
  index,
  locale,
  project,
  editablePages,
}: QuestionEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();
  const proposalViewRdx = useSelector((s: RootState) => s.editModeProposalView);
  if (!proposalBlocks[index]) return null;

  const { data } = proposalBlocks[index];
  if (data.type !== EDITABLE_CONTENT_BLOCK_TYPES.QUESTION) return null;
  const questionType = data.content.type;
  const { id, ...restContent } = data.content;

  return (
    <QuestionsEditorSelector
      key={id}
      questionType={questionType}
      id={id}
      content={restContent}
      onContentUpdate={handleContentUpdate}
      allowBlurLabelUpdate={
        proposalViewRdx[locale].stage === ProposalStage.DRAFT ||
        project.stage === ProjectStage.TESTING
      }
      features={project.features}
      editablePages={editablePages}
    />
  );
};
