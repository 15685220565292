import styled from 'styled-components';
import { SimpleSelect } from 'Client/components/molecules';

export const Select = styled(SimpleSelect)`
  width: 100%;
  margin: 0.5rem 0;

  .react-select__control {
    width: 100%;
    min-height: 2.5rem;
    border-radius: 0.5rem;
    border: 0.063rem solid ${({ theme }) => theme.colors.grey[400]};

    .react-select__value-container {
      min-height: 2.5rem;
      padding: 0.125rem 0.5rem;

      .MuiListItemText-primary {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .react-select__placeholder {
      font-size: 1rem;
    }
  }

  .react-select__menu {
    width: 100%;
  }
` as typeof SimpleSelect;

export const InputWrapper = styled.div`
  > input {
    height: 2.5rem;
    border-radius: 0.5rem;
    border: 0.063rem solid ${({ theme }) => theme.colors.grey[400]};
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme }) => theme.colors.black[500]};

    &::placeholder {
      font-size: 1rem;
    }
  }
`;
