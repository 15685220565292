import React from 'react';
import { useEditModeContext } from 'Client/utils/hooks';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { AccordionEditor } from '../../../Editor';
import { CommonEditorProps } from './types';
import { AccordionEditorProps } from '../../../Editor/AccordionEditor/AccordionEditor';

export const RenderAccordionEditor = ({
  currentView,
  handleContentUpdate,
  index,
  hubPageContent,
}: CommonEditorProps) => {
  const [{ proposalBlocks }] = useEditModeContext();

  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    if (!proposalBlocks[index]) return null;

    const { data } = proposalBlocks[index];
    return (
      <AccordionEditor
        onContentUpdate={handleContentUpdate}
        data={data}
        key={`${index}-media-upload-key`}
        index={index}
        isHub={false}
      />
    );
  }
  if (currentView.type === EDITABLE_PAGE_TYPES.HUB) {
    if (!hubPageContent) return null;
    const {
      content: { sections },
    } = hubPageContent;
    const data = sections[index] as AccordionEditorProps['data'];

    return (
      <AccordionEditor
        onContentUpdate={handleContentUpdate}
        data={data}
        key={`${index}-media-upload-key`}
        index={index}
        isHub={true}
      />
    );
  }
};
