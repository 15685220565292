import { gql } from '@apollo/client';

export const EDIT_PAGE = gql`
  mutation EditPage($editPageInput: EditPageInput) {
    editPage(editPageInput: $editPageInput) {
      newPage {
        _id
        projectId
        type
        slug
        createdAt
        active
        content
        contentVersionId
        userId
        category
        order
      }
      success
    }
  }
`;
