import React from 'react';
import { useRouter } from 'next/router';
import { DynamicProposalsContent } from 'Client/pages/shared/tiles/types';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { HubContent } from 'Client/pages/hub';
import { useEditModeContext } from 'Client/utils/hooks';
import { DynamicProposalsEditor } from '../../../Editor';

interface DynamicProposalsEditorProps {
  pageType: EDITABLE_PAGE_TYPES;
  pageContent:
    | HubContent
    | { content: { sections: { content: unknown; _id?: string }[] } }; // @TBI
  handleContentUpdate: (content: unknown) => void;
  index?: number;
}
export const RenderDynamicProposalsEditor = ({
  pageContent,
  handleContentUpdate,
  index,
  pageType,
}: DynamicProposalsEditorProps) => {
  const router = useRouter();
  const [{ proposalsPageState }] = useEditModeContext();

  if (pageType === EDITABLE_PAGE_TYPES.PROPOSALS) {
    const block = proposalsPageState[router.locale]?.blocks?.[index];
    if (!block?.content) return null;
    return (
      <DynamicProposalsEditor
        pageType={pageType}
        content={block?.content}
        index={index}
        contextDispatcher={handleContentUpdate}
        id={block?._id}
      />
    );
  }
  if (!pageContent) return null;
  const {
    content: { sections },
  } = pageContent;
  const dynamicProposals = sections[index];
  if (!dynamicProposals || !dynamicProposals.content) return null;
  const content: DynamicProposalsContent = {
    ...(dynamicProposals.content as unknown as DynamicProposalsContent),
    id: dynamicProposals._id,
  };
  return (
    <DynamicProposalsEditor
      pageType={pageType}
      content={content}
      index={index}
      contextDispatcher={handleContentUpdate}
      id={dynamicProposals?._id}
    />
  );
};
