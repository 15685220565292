import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { DraggableSection } from 'Organisms';
import { useEditModeContext } from 'Client/utils/hooks';
import { StakeholderCard } from 'Client/pages/projectTeam/components';
import { Container } from 'Atoms';
import {
  Heading,
  Intro,
} from 'Client/pages/projectTeam/ProjectTeamPage.styles';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../../constants';

interface ProjectTeamViewProps {
  onCurrentEditItem: (type: string, index: number) => void;
}

export const ProjectTeamView: React.FC<ProjectTeamViewProps> = ({
  onCurrentEditItem,
}: ProjectTeamViewProps) => {
  const [{ projectTeamPage }] = useEditModeContext();
  const { t } = useTranslation();

  return (
    <Droppable droppableId="existing-hub-sections">
      {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
        <div ref={providedDrop.innerRef} data-testid="ProjectTeamView">
          <DraggableSection
            key={`span:${EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER}`}
            id={`span:${EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER}`}
            index={0}
            isDraggingOver={snapshotDrop.isDraggingOver}
          >
            <div
              data-testid={'teams-block-item'}
              data-scroll-id={'teams-header'}
              onClick={() =>
                onCurrentEditItem(
                  EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER,
                  0
                )
              }
            >
              <Container>
                <Heading variant="h1">{projectTeamPage.content.title}</Heading>
                <Intro>{projectTeamPage.content.description}</Intro>
              </Container>
            </div>
          </DraggableSection>
          {projectTeamPage.content?.stakeholders?.map((stakeholder, index) => {
            return (
              <DraggableSection
                key={`span:${EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION}-${index}`}
                id={`span:${EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION}-${index}`}
                index={index}
                isDraggingOver={snapshotDrop.isDraggingOver}
              >
                <div
                  data-testid={'teams-block-item'}
                  data-scroll-id={stakeholder?._id}
                >
                  <span
                    key={`span:${EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION}-${index}`}
                    data-testid={`span:${EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION}-${index}`}
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCurrentEditItem(
                        EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION,
                        index + 1
                      );
                    }}
                  >
                    <StakeholderCard
                      key={`${stakeholder.name}-${index}`}
                      name={stakeholder.name}
                      logo={stakeholder.logo}
                      logoAltText={
                        stakeholder?.privacyPolicy
                          ? t(
                              'A link redirecting to the stakeholder privacy policy'
                            )
                          : stakeholder.logoAltText
                      }
                      privacyPolicy={t(`Read {{name}} privacy policy`, {
                        name: stakeholder.name,
                      })}
                      responsibilities={stakeholder?.responsibilities}
                      url={stakeholder?.privacyPolicy}
                      role={stakeholder?.role}
                    />
                  </span>
                </div>
              </DraggableSection>
            );
          })}
        </div>
      )}
    </Droppable>
  );
};
