import styled from 'styled-components';
import { CircleCheckIcon as CircleCheckIconBase } from 'Icons';
import { HtmlDescription } from 'Molecules';

export const Wrapper = styled.div`
  padding: 2rem 0 ${({ theme }) => theme.proposals.footer.height};
`;
export const DemographicsHeaderSection = styled.div`
  background-color: #f9f9f9;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  gap: 2rem;
  position: relative;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')}{
      flex-direction: column-reverse;
      padding: 0 5%;
      padding-top: 6rem;
      padding-bottom: 3rem;
    }
    ${theme.breakpoints.up('sm')}{
      padding: 0 15%;
      padding-top: 6rem;
      padding-bottom: 3rem;
    }
  `}
`;

export const HeaderTextContent = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')}{
      flex-direction: column-reverse;
      width: 100%;
      text-align: center;
      & > div {
        padding-top: 2rem;
      }
    }
    ${theme.breakpoints.up('md')}{
      width: 55%;
    }
  `}
`;

export const HeaderImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IntroDescription = styled(HtmlDescription)`
  padding-top: 1rem;
  color: ${({ theme }) => theme.colors.black[500]};
`;

export const CircleCheckIcon = styled(CircleCheckIconBase)`
  ${({ theme }) =>
    theme.direction === 'rtl' ? 'margin-left: 0.5rem' : 'margin-right: 0.5rem'};
  color: ${({ theme }) => theme.colorMappings.CommonplaceBrand};
`;

export const FooterTextWrapper = styled.div`
  margin-top: 2rem;
`;
