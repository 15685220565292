import { gql } from '@apollo/client';

export const DUPLICATE_PROPOSAL_CONTENT = gql`
  mutation DuplicateContent($duplicateContentInput: DuplicateContentInput) {
    duplicateContent(duplicateContentInput: $duplicateContentInput) {
      message
      importedFrom
      importedTo
      success
    }
  }
`;
