import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useProject, useMap } from 'Client/utils/hooks';
import { ProposalStage } from 'Client/pages/proposals/types';
import { deleteProposalPage } from 'Client/services/page';
import { ConfirmationModal } from 'Organisms';
import { ProjectStage } from 'Shared/types';
import { Button, Icon } from './DeletePageButton.styles';

const DeletePageButton = (): JSX.Element => {
  const router = useRouter();
  const project = useProject();
  const { t } = useTranslation('customer');
  const {
    state: { proposal },
  } = useMap();

  const [isButtonShown, setIsButtonShown] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

  const handleClick = () => {
    setIsConfirmModalOpen(true);
  };

  const handleClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleDelete = async () => {
    const res = await deleteProposalPage(
      proposal.pageId,
      proposal.slug,
      project.id
    );
    if (res.success) {
      setIsConfirmModalOpen(false);
      router.push('/edit');
    }
  };

  React.useEffect(() => {
    const canBeShown =
      proposal.stage === ProposalStage.DRAFT ||
      project.stage === ProjectStage.TESTING;

    setIsButtonShown(canBeShown);
  }, [project, proposal]);

  return isButtonShown ? (
    <>
      <Button onClick={handleClick}>
        <Icon width="0.9375rem" height="0.9375rem" />
        {t('Delete map')}
      </Button>

      <ConfirmationModal
        open={isConfirmModalOpen}
        title={t('Delete map')}
        description={t(
          `Are you sure you want to delete this page and all it's content? This means any links for this page that have already been shared will no longer work.`
        )}
        confirmLabel={t('Yes, delete map')}
        discardLabel={t('No, keep map')}
        onClose={handleClose}
        onConfirm={handleDelete}
        onDiscard={handleClose}
      />
    </>
  ) : null;
};

export { DeletePageButton };
