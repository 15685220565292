import styled, { css } from 'styled-components';

export const SmartPhone = styled.div<{ editModeDevice: 'mobile' | 'desktop' }>`
  ${({ theme, editModeDevice }) => css`
    display: flex;
    flex-direction: column;
    height: 41rem;
    overflow-y: auto;
    left: 50%;
    ${editModeDevice == 'mobile'
      ? css`
          top: 31rem;
          box-shadow:
            0 0 0 0.125rem ${theme.colors.grey[400]},
            0 0 0 0.625rem ${theme.colors.white[500]},
            0 0 0 0.75rem ${theme.colors.grey[400]};
          padding: 0.75rem;
          width: 22rem;
          border-radius: 2.5rem;
        `
      : css`
          height: calc(100% - 3rem); // 3rem is the 'tabs' height
          margin-top: 3rem;
          width: 100%;
          border-radius: 0.3rem;
          box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.1);
        `};

    background-color: ${theme.colors.white[500]};

    @media screen and (max-height: 54.5rem) {
      height: calc(100% - 3rem); // 3rem is the 'tabs' height
      margin-top: 3rem;
      padding: 0.75rem 0;
      width: ${editModeDevice == 'mobile' ? '20rem' : '100%'};
      ${editModeDevice == 'mobile'
        ? css`
            box-shadow:
              0 0 0 0.125rem ${theme.colors.grey[400]},
              0 0 0 0.5rem ${theme.colors.white[500]},
              0 0 0 0.625rem ${theme.colors.grey[400]};
          `
        : ''}
    }
  `};
`;

export const BottomNavigation = styled.img`
  padding-top: 0.5rem;
  ${({ theme }) => theme.direction === 'rtl' && 'transform: scaleX(-1)'};

  @media screen and (max-height: 54.5rem) {
    height: auto;
    width: 85%;
    margin: 0 auto -0.5rem;
    padding-top: 0;
  }
`;

export const PhoneHeader = styled.div`
  width: 80%;
  height: 10rem;
  align-self: center;
  border-radius: 0.375rem;
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.colors.grey[200]};

  @media screen and (max-height: 54.5rem) {
    width: 75%;
    height: 5rem;
    margin: 0.125rem 0 0.75rem;
    position: relative;
    z-index: 10;
  }
`;

export const Content = styled.div<{
  editModeDevice: 'desktop' | 'mobile';
  scaledDownLayout: boolean;
}>`
  ${({ editModeDevice, scaledDownLayout }) => css`
    ${editModeDevice === 'mobile' && 'overflow-y: auto;'}
    ${scaledDownLayout && 'transform: scale(0.8);'}
  `}
  min-height: 80%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  [data-disable-events] {
    pointer-events: none;
  }
`;

export const ProposalsViewWrapper = styled.div`
  position: relative;
  padding: 2rem 0;
  & > span {
    padding: 0 2rem;
  }
  & > div > div {
    padding: 0 1.5rem;
  }
`;

export const NavPreview = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 1rem;
  padding-bottom: 0.5rem;
`;
