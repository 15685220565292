import * as React from 'react';
import { useRouter } from 'next/router';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { HubView } from './HubView';
import { ProposalView } from './ProposalView';
import { MilestonesView } from './MilestonesView';
import { EDITABLE_PAGE_TYPES } from '../../constants';
import { SmartphoneView } from './SmartphoneView';
import { MobilePreviewProps } from './types';
import { ProjectTeamView } from './ProjectTeamView';
import { PreferencesView } from './PreferencesView';
import { DemographicsView } from './DemographicsView';
import { ProposalsView } from './ProposalsView';

export const MobilePreview: React.FC<MobilePreviewProps> = ({
  currentView,
  proposals,
  // proposalsPage,
  newsPosts,
  milestones,
  stakeholders,
  onCurrentEditItem,
  benchmarkQuestions,
  editModeDevice,
}: MobilePreviewProps) => {
  const [, setEditModeLayout] = useEditModeLayout();
  const router = useRouter();

  React.useEffect(() => {
    setEditModeLayout(router.asPath.includes('/edit'));
    /* Handle to set as false when changing routes (out of edit mode) */
    router?.events?.on('routeChangeComplete', (route: string) => {
      setEditModeLayout(route.includes('/edit'));
    });
  }, [router]);

  React.useEffect(() => {
    if (currentView.type === EDITABLE_PAGE_TYPES.MAP) setEditModeLayout(true);
  }, []);

  return (
    currentView.type && (
      <SmartphoneView editModeDevice={editModeDevice}>
        {currentView.type === EDITABLE_PAGE_TYPES.HUB && (
          <HubView
            proposals={proposals}
            newsPosts={newsPosts}
            milestones={milestones}
            stakeholders={stakeholders}
            onCurrentEditItem={onCurrentEditItem}
          />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL && (
          <ProposalView onCurrentEditItem={onCurrentEditItem} />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.MAP && (
          <ProposalView onCurrentEditItem={onCurrentEditItem} />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.MILESTONES && (
          <MilestonesView onCurrentEditItem={onCurrentEditItem} />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.TEAMS && (
          <ProjectTeamView onCurrentEditItem={onCurrentEditItem} />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.PREFERENCES && (
          <PreferencesView onCurrentEditItem={onCurrentEditItem} />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.DEMOGRAPHICS && (
          <DemographicsView
            onCurrentEditItem={onCurrentEditItem}
            stakeholders={stakeholders}
            benchmarkQuestions={benchmarkQuestions}
          />
        )}
        {currentView.type === EDITABLE_PAGE_TYPES.PROPOSALS && (
          <ProposalsView
            onCurrentEditItem={onCurrentEditItem}
            // proposalsPage={proposalsPage}
          />
        )}
      </SmartphoneView>
    )
  );
};
