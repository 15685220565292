import React from 'react';
import { ContactTeamContent } from 'Client/pages/hub';
import { ContactTeamEditor } from '../../../Editor';
import { HubEditorProps } from './types';
export const RenderContactTeamEditor = ({
  hubPageContent,
  handleContentUpdate,
  index,
}: HubEditorProps) => {
  if (!hubPageContent) return null;
  const {
    content: { sections },
  } = hubPageContent;
  const contactTeam = sections[index];
  return (
    <ContactTeamEditor
      content={contactTeam.content as ContactTeamContent}
      onContentUpdate={handleContentUpdate}
      index={index}
      key={`${index}-contact-team-key`}
    />
  );
};
