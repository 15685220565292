import styled, { css } from 'styled-components';
import Card from '@material-ui/core/Card';
import { darken } from 'polished/lib';
import { ProposalCard } from 'Atoms';
import { Button } from 'Molecules';

export const SmallProposalCard = styled(ProposalCard)`
  margin: 1rem auto;
  width: 11.25rem;
  height: 14.5rem;

  .MuiCardMedia-root {
    height: 5.625rem;
  }

  .card-title {
    font-size: 1.1875rem;
  }

  .card-description {
    font-size: 1rem;
  }
`;

export const PanelContent = styled(Card)`
  padding: 1rem 2rem 2rem 2rem;
  height: 100%;
  overflow-y: auto;
`;

export const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.colorMappings.error};
  border-color: ${({ theme }) => theme.colorMappings.error};
  color: ${({ theme }) => theme.colorMappings.white};
  font-size: 1rem;
  padding: 0.3125rem 0.9375rem;
  min-height: 3rem;

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colorMappings.error)};
    border-color: ${({ theme }) => darken(0.1, theme.colorMappings.error)};
    color: ${({ theme }) => theme.colorMappings.white};
  }
`;

export const DuplicateTileButton = styled(Button)`
  ${({ theme }) => css`
    background-color: ${theme.colors.green['A700']};
    color: white;
    border-radius: 0.2344rem;
    width: 100%;
    height: 2.5rem;
    border: none;
    font-size: 0.9375rem;
    &:hover {
      background-color: ${darken(0.1, theme.colors.green['A700'])};
      color: white;
      border: none;
    }
    &:focus {
      &:after {
        content: none;
      }
      border: 2.5px solid ${theme.colorMappings.primaryButtonFocus};
    }
  `}
`;

export const DuplicateTileSection = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  color: ${theme.colors.black[50]};
  &:first-child{
    font-size 1.25rem;
  }
  & > p {
    margin: 0;
  }
  `}
`;
