import React from 'react';
import { useRouter } from 'next/router';
import {
  EDITABLE_CONTENT_BLOCK_TYPES,
  EDITABLE_PAGE_TYPES,
} from 'Client/pages/edit/constants';
import { useProject } from 'Client/utils/hooks';
import { filterCustom } from 'Client/pages/edit/utils';
import { EditPanelProps } from '../types';
import {
  RenderAccordionEditor,
  RenderDescriptionEditor,
  RenderDynamicProposalsEditor,
  RenderHeroEditor,
  RenderImageAndGridEditor,
  RenderImageComparisonEditor,
  RenderInputEditor,
  RenderMediaUploadEditor,
  RenderMilestoneEditor,
  RenderOrganisationEditor,
  RenderProgrammeEditor,
  RenderProjectTeamEditor,
  RenderProposalDynamicProposalsEditor,
  RenderQuestionEditor,
  RenderTextEditor,
  RenderTwoColumnsEditor,
  RenderContactTeamEditor,
  RenderImageMapProEditor,
  RenderDemographicsQuestionEditor,
  RenderCustomConsentEditor,
  RenderNavmapEditor,
  RenderProposalMapEditor,
} from './Editors';
import { RenderNavBarEditor } from './Editors/RenderNavBarEditor';
import { RenderTitleEditor } from './Editors/RenderTitleEditor';

export const RenderEditor: React.FC<
  Omit<EditPanelProps, 'onCurrentEditItem'>
> = (props) => {
  const {
    hubPageContent,
    handleContentUpdate,
    index,
    type,
    currentView,
    editablePages,
    isBenchmark,
    setIsCodeViewActive,
    proposals,
  } = props;
  const { locale } = useRouter();
  const project = useProject();
  const displayTrackChanges = project.features?.froalaTrackedChanges;
  const editors = {
    [EDITABLE_CONTENT_BLOCK_TYPES.HERO]: () => (
      <RenderHeroEditor
        hubPageContent={hubPageContent}
        handleContentUpdate={handleContentUpdate}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME]: () => (
      <RenderProgrammeEditor
        hubPageContent={hubPageContent}
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS]: () => {
      const pageContent = {
        [EDITABLE_PAGE_TYPES.HUB]: hubPageContent,
        [EDITABLE_PAGE_TYPES.PROPOSALS]: {},
      }[currentView.type];
      return (
        <RenderDynamicProposalsEditor
          pageContent={pageContent}
          handleContentUpdate={handleContentUpdate}
          index={index}
          pageType={currentView.type}
        />
      );
    },
    [EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_DYNAMIC_PROPOSALS]: () => (
      <RenderProposalDynamicProposalsEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.TEXT]: () => (
      <RenderTextEditor
        hubPageContent={hubPageContent}
        handleContentUpdate={handleContentUpdate}
        index={index}
        currentView={currentView}
        displayTrackChanges={displayTrackChanges}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.TITLE]: () => (
      <RenderTitleEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
        currentView={currentView}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.DESCRIPTION]: () => (
      <RenderDescriptionEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
        currentView={currentView}
        displayTrackChanges={displayTrackChanges}
        setIsCodeViewActive={setIsCodeViewActive}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.INPUT]: () => (
      <RenderInputEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
        currentView={currentView}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.QUESTION]: () => (
      <RenderQuestionEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
        locale={locale}
        project={project}
        editablePages={editablePages}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE]: () => (
      <RenderMilestoneEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE]: () => (
      // that's only a placehodler obviously, TODO
      <div>Hello there</div>
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_COMPARISON]: () => (
      <RenderImageComparisonEditor
        currentView={currentView}
        handleContentUpdate={handleContentUpdate}
        index={index}
        hubPageContent={hubPageContent}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_AND_GRID]: () => (
      <RenderImageAndGridEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.MEDIA_UPLOAD]: () => (
      <RenderMediaUploadEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),

    [EDITABLE_CONTENT_BLOCK_TYPES.CONTACT_TEAM]: () => (
      <RenderContactTeamEditor
        hubPageContent={hubPageContent}
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.PROJECT_TEAM_HEADER]: () => (
      <RenderProjectTeamEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.ORGANISATION]: () => (
      <RenderOrganisationEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.IMAGE_MAP_PRO]: () => (
      <RenderImageMapProEditor
        currentView={currentView}
        hubPageContent={hubPageContent}
        handleContentUpdate={handleContentUpdate}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.CUSTOM_CONSENT]: () => (
      <RenderCustomConsentEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
        filterCustom={filterCustom}
        locale={locale}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.DEMOGRAPHICS_QUESTION]: () => (
      <RenderDemographicsQuestionEditor
        index={index}
        type={type}
        isBenchmark={isBenchmark}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.SPECIAL_DEMOGRAPHICS_QUESTION]: () => (
      <RenderDemographicsQuestionEditor
        index={index}
        type={type}
        isBenchmark={isBenchmark}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.ACCORDION]: () => (
      <RenderAccordionEditor
        handleContentUpdate={handleContentUpdate}
        index={index}
        currentView={currentView}
        hubPageContent={hubPageContent}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.TWO_COLUMNS]: () => (
      <RenderTwoColumnsEditor
        currentView={currentView}
        handleContentUpdate={handleContentUpdate}
        index={index}
        hubPageContent={hubPageContent}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.NAVIGATIONAL_MAP]: () => (
      <RenderNavmapEditor
        hubPageContent={hubPageContent}
        handleContentUpdate={handleContentUpdate}
        proposals={proposals}
        index={index}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.PROPOSAL_MAP]: () => (
      <RenderProposalMapEditor
        handleContentUpdate={handleContentUpdate}
        proposals={proposals}
        index={index}
        currentView={currentView}
      />
    ),
    [EDITABLE_CONTENT_BLOCK_TYPES.NAV]: () => (
      <RenderNavBarEditor editablePages={editablePages} />
    ),
  };
  return editors[type]() || null;
};
