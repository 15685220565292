import { SelectedViewOption } from 'Client/pages/edit';

/**
 * Returns an array of slugs as strings without the initial "/" character
 * and without the slug passed as param
 */

export const getSlugReference = (
  slug: string,
  pages: Array<SelectedViewOption>
): Array<string> => {
  return pages
    .map((p) => (p.value.charAt(0) === '/' ? p.value.substring(1) : p.value))
    .filter((s) => s !== slug)
    .filter(Boolean);
};
