import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMapFeatureForm } from 'Client/utils/hooks/useMapFeatureForm';
import { PageTypes } from 'Shared/types/page';
import { useMap } from 'Client/utils/hooks';
import {
  Button,
  ButtonContainer,
  Checkbox,
  CircleIcon,
  Heading,
  InnerPanelContainer,
  MarkerIcon,
  PanelContainer,
  PolygonIcon,
  Select,
  TextArea,
  TextField,
  RadioButton,
  RadioButtonContainer,
} from './AddFeaturePanel.styles';

type Props = {
  handleSaveDrawing: () => void;
  handleCancelDrawing: () => void;
  featureType: null | 'point' | 'polygon' | 'circle';
  isLandmark: boolean;
};

export const AddFeaturePanel = ({
  handleSaveDrawing,
  handleCancelDrawing,
  featureType,
  isLandmark,
}: Props): JSX.Element => {
  const { t } = useTranslation('customer');
  const {
    state: { proposals },
  } = useMap();
  const {
    imageUrl,
    setImageUrl,
    description,
    setDescription,
    title,
    setTitle,
    setProposal,
    proposal,
    hoverablePopup,
    setHoverablePopup,
    setIsPlanningApp,
    landmark,
    setLandmark,
  } = useMapFeatureForm();
  const [featureBasis, setFeatureBasis] = React.useState<
    'custom' | 'template' | null
  >(null);
  const [proposalPages, setProposalPages] = React.useState([]);

  React.useEffect(() => {
    const transformedProposals = proposals.map((page) => {
      if (page.type === PageTypes.PLANNING_APP) {
        return {
          label: page.address,
          value: page.address,
          slug: page.slug,
          description: page.proposal,
          image: null,
          id: page._id,
          type: page.type,
        };
      }
      return {
        label: page.card.title,
        value: page.card.title,
        slug: page.slug,
        description: page?.card?.description,
        image: page.card?.image?.src,
        id: page._id,
        type: page.type,
      };
    });
    setProposalPages(transformedProposals);
  }, []);

  const renderIcon = (featureType: Props['featureType']) => {
    if (featureType === 'point') {
      return <MarkerIcon color="#f4501e" />;
    }
    if (featureType === 'polygon') {
      return <PolygonIcon color="#f4501e" />;
    }
    if (featureType === 'circle') {
      return <CircleIcon color="#f4501e" />;
    }
    return null;
  };

  const selectFeatureBasis = (basis: 'custom' | 'template') => () => {
    setTitle(
      {
        template: '',
        custom: {
          point: t('Point 1'),
          polygon: t('Polygon 1'),
          circle: t('Circle 1'),
        }[featureType],
      }[basis] || ''
    );
    setDescription(
      {
        template: '',
        custom: {
          point: t('Point 1 description'),
          polygon: t('Polygon 1 description'),
          circle: t('Circle 1 description'),
        }[featureType],
      }[basis] || ''
    );
    setImageUrl('');
    setProposal(null);
    setHoverablePopup(false);
    setFeatureBasis(basis);
  };

  return (
    <PanelContainer isOpen>
      <InnerPanelContainer>
        <RadioButtonContainer>
          <RadioButton
            data-testid="panel-radio-select"
            label={t('Create custom')}
            checked={featureBasis === 'custom'}
            onChange={selectFeatureBasis('custom')}
          />

          <RadioButton
            data-testid="panel-radio-select"
            label={t('Pick from list')}
            checked={featureBasis === 'template'}
            onChange={selectFeatureBasis('template')}
          />
        </RadioButtonContainer>

        {featureBasis && (
          <>
            <Heading data-testid="panel-header">
              {renderIcon(featureType)}
              {title}
            </Heading>

            {/* <ImagePlaceholder>
              <ImagePlaceholderTextContainer>
                <ImagePlaceholderTitle>
                  {t('No image for this location')}
                </ImagePlaceholderTitle>
                <ImagePlaceholderDescription>
                  {t('Link to design proposal or')} {'\n'}
                  <AddImage>{t('add an image')}</AddImage>
                </ImagePlaceholderDescription>
              </ImagePlaceholderTextContainer>
            </ImagePlaceholder> */}

            {featureBasis === 'custom' && (
              <>
                <TextField
                  placeholder={t('Image')}
                  label={t('Image')}
                  value={imageUrl}
                  handleChange={(e) => {
                    setImageUrl(e.target.value as string);
                  }}
                />

                {/* <ColorSwatchContainer data-testid="color-swatch">
                  <ExpandMoreIcon />
                  <ColorSwatch />
                </ColorSwatchContainer> */}

                <TextField
                  label={t('Title')}
                  value={title}
                  handleChange={(e) => {
                    setTitle(e.target.value as string);
                  }}
                />

                <TextArea
                  value={description}
                  handleChange={(e) => {
                    setDescription(e.target.value as string);
                  }}
                />

                <Checkbox
                  label={t('show hoverable popup')}
                  onChange={(e) => {
                    setHoverablePopup(e.target.checked);
                  }}
                  status={{ show: false }}
                  checked={hoverablePopup}
                />
              </>
            )}

            {featureBasis === 'template' && (
              <Select
                noFocusHighlight
                value={proposal}
                isClearable={false}
                handleChange={(e) => {
                  setTitle(e.label);
                  setDescription(e.description);
                  setImageUrl(e.image);
                  setProposal(e);
                  setHoverablePopup(true);
                  setIsPlanningApp(e.type && e.type === PageTypes.PLANNING_APP);
                }}
                options={proposalPages}
              />
            )}

            {isLandmark && (
              <TextArea
                value={landmark}
                handleChange={(e) => {
                  setLandmark(e.target.value as string);
                }}
                placeholder="Custom svg. E.g. <svg>.....</svg>"
              />
            )}

            <ButtonContainer>
              <Button
                data-testid="panel-button"
                inverse
                onClick={handleCancelDrawing}
              >
                {t('Cancel')}
              </Button>

              <Button data-testid="panel-button" onClick={handleSaveDrawing}>
                {t('Save')}
              </Button>
            </ButtonContainer>
          </>
        )}
      </InnerPanelContainer>
    </PanelContainer>
  );
};
