import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { List, TitleWrapper, Title } from './Revision.styles';
import { RevisionsBlock } from './RevisionsBlock';
import { RevisionsProps } from './types';

export const Revisions: React.FC<RevisionsProps> = ({
  revisions,
  currentView,
}: RevisionsProps) => {
  const { t } = useTranslation('customer');
  return (
    <>
      <TitleWrapper>
        <Title>{t('Revisions')}</Title>
      </TitleWrapper>
      <List data-onboarding="edit-mode-revisions-list">
        {revisions && (
          <RevisionsBlock revisions={revisions} currentView={currentView} />
        )}
      </List>
    </>
  );
};
