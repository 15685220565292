import * as React from 'react';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Typography } from 'Atoms';
import { RootState } from 'Client/redux-store';
import {
  FooterTextWrapper,
  IntroDescription,
} from 'Client/pages/demographics/DemographicsPage.styles';
import {
  DemographicsHeader,
  MeetTheTeamPanel,
} from 'Client/pages/demographics/components';
import { SPECIAL_CATEGORY_DATA } from 'Client/constants/demographics';
import { insertProjectNames } from 'Client/pages/demographics/utils/insertProjectsNames';
import { useProject } from 'Client/utils/hooks';
import { EditPageProps } from '../../..';
import { QuestionsContainer } from './QuestionsContainer';
import { SpecialQuestionsContainer } from './SpecialQuestionsContainer';

interface DemographicsViewProps extends Partial<EditPageProps> {
  onCurrentEditItem: (
    type: string,
    index: number,
    isBenchmark: boolean
  ) => void;
}

export const DemographicsView: React.FC<DemographicsViewProps> = ({
  onCurrentEditItem,
  stakeholders,
  benchmarkQuestions,
}: DemographicsViewProps) => {
  const router = useRouter();
  const project = useProject();
  const demographicsView = useSelector(
    (state: RootState) => state.editModeDemographicsView
  );

  if (
    !demographicsView[router.locale] ||
    !demographicsView[router.locale]?.content
  )
    return <></>;

  const {
    content: {
      header,
      subheader,
      teamSectionIntro,
      teamSectionFooter,
      questions,
      specialCategoryTitle,
      specialCategoryDescription,
      footerText,
    },
  } = demographicsView[router.locale];

  const nonSpecialQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType !== SPECIAL_CATEGORY_DATA
  );
  const specialCategoryQuestions = questions?.filter(
    (dq) => dq?.content?.sensitiveType === SPECIAL_CATEGORY_DATA
  );

  const treatedSubheader = insertProjectNames(subheader, project.name);
  const treatedTeamSectionIntro = insertProjectNames(
    teamSectionIntro,
    project.name
  );
  const treatedTeamSectionFooter = insertProjectNames(
    teamSectionFooter,
    project.name
  );

  return (
    <div style={{ position: 'relative', padding: '2rem' }}>
      <DemographicsHeader>{header}</DemographicsHeader>
      <IntroDescription>{treatedSubheader}</IntroDescription>

      <Droppable droppableId="demographics-view">
        {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
          <div ref={providedDrop.innerRef}>
            <QuestionsContainer
              questions={nonSpecialQuestions}
              onCurrentEditItem={onCurrentEditItem}
              snapshotDrop={snapshotDrop}
              benchmarkQuestions={benchmarkQuestions}
            />
          </div>
        )}
      </Droppable>

      <Droppable droppableId={SPECIAL_CATEGORY_DATA}>
        {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
          <div ref={providedDrop.innerRef}>
            <SpecialQuestionsContainer
              title={specialCategoryTitle}
              description={specialCategoryDescription}
              questions={specialCategoryQuestions}
              onCurrentEditItem={onCurrentEditItem}
              snapshotDrop={snapshotDrop}
              nonSpecialTotal={nonSpecialQuestions?.length || 0}
              benchmarkQuestions={benchmarkQuestions}
            />
          </div>
        )}
      </Droppable>
      <MeetTheTeamPanel
        intro={treatedTeamSectionIntro}
        footer={treatedTeamSectionFooter}
        stakeholders={stakeholders}
      />
      {footerText && (
        <FooterTextWrapper>
          <Typography>{footerText}</Typography>
        </FooterTextWrapper>
      )}
    </div>
  );
};
