import * as React from 'react';
import { RevisionIcon, SummaryIcon } from 'Icons';
import { EDITABLE_PAGE_TYPES } from '../../constants';
import { TabsLayout } from '../TabsLayout';
import { Revisions } from './Revisions';
import { SummaryProps } from './types';
import { ProposalCardEditPanel } from './';

export const Summary: React.FC<SummaryProps> = (props: SummaryProps) => {
  const { revisions, currentView, editablePages } = props;
  const isProposal = currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL;
  const [tabValue, setTabValue] = React.useState(0);

  const contents = [
    {
      icon: <SummaryIcon />,
      title: 'Summary',
      element: <ProposalCardEditPanel editablePages={editablePages} />,
    },
    {
      icon: <RevisionIcon />,
      title: 'Revisions',
      element: <Revisions revisions={revisions} currentView={currentView} />,
    },
  ];

  const contentsToBeDisplay = contents.filter((content) =>
    isProposal ? contents : content.title === 'Revisions'
  );

  return (
    <TabsLayout
      value={tabValue}
      setValue={setTabValue}
      contents={contentsToBeDisplay}
    />
  );
};
