import React from 'react';
import { ImageMapProContent } from 'Client/pages/hub/types';
import { EDITABLE_PAGE_TYPES } from 'Client/pages/edit/constants';
import { useEditModeContext } from 'Client/utils/hooks';
import { ImageMapProEditor } from '../../../Editor';

export const RenderImageMapProEditor = ({
  currentView,
  hubPageContent,
  handleContentUpdate,
  index,
}) => {
  const [{ proposalBlocks }] = useEditModeContext();
  if (currentView.type === EDITABLE_PAGE_TYPES.PROPOSAL) {
    if (!proposalBlocks[index]) return null;

    const { data } = proposalBlocks[index];
    return (
      <ImageMapProEditor
        isProposal
        content={{ ...data.content, _id: data._id } as ImageMapProContent}
        onContentUpdate={handleContentUpdate}
        index={index}
        key={`${index}-image-map-pro-key`}
      />
    );
  }

  if (currentView.type === EDITABLE_PAGE_TYPES.HUB) {
    if (!hubPageContent) return null;
    const {
      content: { sections },
    } = hubPageContent;
    const imageMap = sections[index];
    return (
      <ImageMapProEditor
        content={
          { ...imageMap.content, _id: imageMap._id } as ImageMapProContent
        }
        onContentUpdate={handleContentUpdate}
        index={index}
        key={`${index}-image-map-pro-key`}
      />
    );
  }
};
