import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabel } from 'Atoms';
import { ActionDropdown } from 'Client/components/molecules/ActionsDropdown';
import { CTA_TEXT_OPTIONS } from 'Client/constants/ctaText';
import { InputWrapper } from './styles';

interface Props {
  onChange?: (value: string) => void;
  defaultValue?: string;
}

export const CTADropdown: React.FC<Props> = ({
  onChange,
  defaultValue,
}: Props) => {
  const { t } = useTranslation('customer');

  return (
    <InputWrapper>
      <InputLabel>{t('Call to action button')}</InputLabel>
      <ActionDropdown
        options={CTA_TEXT_OPTIONS}
        variant="grouped"
        placeholder={t('None')}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </InputWrapper>
  );
};
