import * as React from 'react';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { DraggableSection } from 'Organisms';
import { MilestoneItemProps } from 'Client/pages';
import { Timeline } from 'Client/pages/timeline/MilestonesPage.styles';
import { MilestoneItem } from 'Client/pages/timeline';
import { useEditModeContext } from 'Client/utils/hooks';
import { EDITABLE_CONTENT_BLOCK_TYPES } from '../../constants';
import { PageViewProps } from './types';

export const MilestonesView: React.FC<PageViewProps> = ({
  onCurrentEditItem,
}: PageViewProps) => {
  const [{ milestonesPage }] = useEditModeContext();

  if (!milestonesPage || !milestonesPage.milestones) {
    return (
      <div dir="ltr">
        {'There is no timeline page for this language, please add one.'}
      </div>
    );
  }
  if (milestonesPage.milestones && milestonesPage.milestones.length === 0) {
    return <div dir="ltr">{'There are no milestones, please add one.'}</div>;
  }

  return (
    <Droppable droppableId="existing-milestone-sections">
      {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
        <div ref={providedDrop.innerRef} data-testid="MilestonesView">
          <Timeline data-testid="milestones-view-timeline">
            {milestonesPage.milestones.map(
              (milestoneProps: MilestoneItemProps, index: number) => (
                <DraggableSection
                  key={`${milestoneProps.name}-${index}`}
                  id={`${milestoneProps.name}-${index}`}
                  index={index}
                  isDraggingOver={snapshotDrop.isDraggingOver}
                >
                  <span
                    key={index}
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onCurrentEditItem(
                        EDITABLE_CONTENT_BLOCK_TYPES.MILESTONE,
                        index
                      );
                    }}
                    data-testid="milestone-item-wrapper"
                    data-scroll-id={milestoneProps?._id}
                  >
                    <MilestoneItem {...milestoneProps} />
                  </span>
                </DraggableSection>
              )
            )}
          </Timeline>
        </div>
      )}
    </Droppable>
  );
};
