import * as React from 'react';
import { Content, Link, Wrapper, Message } from './HideContent.styles';
import { HideContentProps } from '.';

const HideContent: React.FC<HideContentProps> = ({
  text,
  link,
}: HideContentProps) => {
  return (
    <Wrapper>
      <Content>
        <Message>
          {text}
          <Link href={link.url}>{link.text}</Link>
        </Message>
      </Content>
    </Wrapper>
  );
};

export { HideContent };
